import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import {
  DetailedAlbumItem,
  PaginatedAlbumTemplateRecursiveList,
  PaginatedDetailedAlbumItemList,
  PaginatedDriveAlbumTemplateRecursiveList,
  PaginatedResponseDriveRestoreList,
  ResponseCloudStorageLimit,
} from '@offconon/core-api';

import * as MediaGalleryActions from './media-gallery.actions';
import { MediaGalleryEntity } from './media-gallery.models';

export const MEDIA_GALLERY_FEATURE_KEY = 'mediaGallery';
type WithSelected<T extends { id?: number }> = T & { selected: boolean };

export type DetailedAlbumItemWithSelected = WithSelected<DetailedAlbumItem>;
export interface PaginatedDetailedAlbumItemListWithSelected
  extends Omit<PaginatedDetailedAlbumItemList, 'results'> {
  results: DetailedAlbumItemWithSelected[];
}
export interface MediaGalleryState extends EntityState<MediaGalleryEntity> {
  selectedMediaIds: number[];
  paginatedFolderList?: PaginatedAlbumTemplateRecursiveList;
  paginatedDriveFolderList?: PaginatedDriveAlbumTemplateRecursiveList;
  paginatedDetailedAlbumItemList?: PaginatedDetailedAlbumItemList;
  storageDetails?: ResponseCloudStorageLimit;
  restorableItems?: PaginatedResponseDriveRestoreList;
  restoredItems?: { albumIdItems: number[]; albumItemIdItems: number[] };
}

export interface MediaGalleryPartialState {
  readonly [MEDIA_GALLERY_FEATURE_KEY]: MediaGalleryState;
}

export const mediaGalleryAdapter: EntityAdapter<MediaGalleryEntity> =
  createEntityAdapter<MediaGalleryEntity>();

export const initialMediaGalleryState: MediaGalleryState = mediaGalleryAdapter.getInitialState({
  selectedMediaIds: [],
});

const reducer = createReducer(
  initialMediaGalleryState,
  on(MediaGalleryActions.toggleSelection, (state, { id }) => {
    const selectedMediaIds = state.selectedMediaIds.includes(id)
      ? state.selectedMediaIds.filter((mediaId) => mediaId !== id)
      : [...state.selectedMediaIds, id];
    return {
      ...state,
      selectedMediaIds,
    };
  }),
  on(MediaGalleryActions.resetSelectedMediaIds, (state) => {
    return {
      ...state,
      selectedMediaIds: [],
    };
  }),
  on(MediaGalleryActions.fetchStorageSuccess, (state, { mediaGallery }) => {
    return {
      ...state,
      paginatedDetailedAlbumItemList: mediaGallery,
    };
  }),
  on(MediaGalleryActions.fetchDriveFoldersSuccess, (state, { driveGallery }) => {
    return {
      ...state,
      paginatedDriveFolderList: driveGallery,
    };
  }),
  on(MediaGalleryActions.getRestorableItemsSuccess, (state, { restorableItems }) => {
    return {
      ...state,
      restorableItems: restorableItems,
    };
  }),
  on(MediaGalleryActions.fetchStorageDetailsSuccess, (state, { storageDetails }) => {
    return {
      ...state,
      storageDetails: storageDetails,
    };
  }),
  on(MediaGalleryActions.fetchAlbumItemsSuccess, (state, { mediaGallery }) => {
    return {
      ...state,
      paginatedDetailedAlbumItemList: mediaGallery,
    };
  }),
  on(MediaGalleryActions.fetchFolderSuccess, (state, { folders }) => {
    return {
      ...state,
      paginatedFolderList: folders,
    };
  }),

  on(MediaGalleryActions.addMediaToStorageSuccess, (state, { media }) => {
    const currentResults = state.paginatedDetailedAlbumItemList?.results || [];
    const updatedResults = [...media, ...currentResults];

    return {
      ...state,
      paginatedDetailedAlbumItemList: {
        ...state.paginatedDetailedAlbumItemList,
        results: updatedResults,
        total_objects: (state.paginatedDetailedAlbumItemList?.total_objects || 0) + media.length,
      },
    };
  }),
  on(
    MediaGalleryActions.restoreItemsSuccess,
    (state, { albumIdItems = [], albumItemIdItems = [] }) => {
      return {
        ...state,
        restoredItems: { albumIdItems, albumItemIdItems },
      };
    },
  ),
  on(MediaGalleryActions.restoreItemsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(MediaGalleryActions.deleteMediaFromStorageSuccess, (state, { id }) => {
    const currentResults = state.paginatedDetailedAlbumItemList?.results || [];
    const updatedResults = currentResults.filter((media) => media.id !== id);

    return {
      ...state,
      paginatedDetailedAlbumItemList: {
        ...state.paginatedDetailedAlbumItemList,
        results: updatedResults,
        total_objects: (state.paginatedDetailedAlbumItemList?.total_objects || 0) - 1,
      },
    };
  }),
  on(MediaGalleryActions.deleteMediaFromAlbumSuccess, (state, { id }) => {
    const currentResults = state.paginatedDetailedAlbumItemList?.results || [];
    const updatedResults = currentResults.filter((media) => media.media_file_id !== id);

    return {
      ...state,
      paginatedDetailedAlbumItemList: {
        ...state.paginatedDetailedAlbumItemList,
        results: updatedResults,
        total_objects: (state.paginatedDetailedAlbumItemList?.total_objects || 0) - 1,
      },
    };
  }),
  on(MediaGalleryActions.deleteMediaItemFromAlbumSuccessArray, (state, { id }) => {
    const currentResults = state.paginatedDetailedAlbumItemList?.results || [];

    // Ha az id egy tömb, akkor szűrjük ki azokat a médiákat, amelyek nem szerepelnek az id tömbben
    const updatedResults = currentResults.filter((media) => !id.includes(media.id || 0));

    return {
      ...state,
      paginatedDetailedAlbumItemList: {
        ...state.paginatedDetailedAlbumItemList,
        results: updatedResults,
        total_objects: (state.paginatedDetailedAlbumItemList?.total_objects || 0) - id.length, // Csökkentjük a total_objects-et a törölt elemek számával
      },
    };
  }),

  on(MediaGalleryActions.deleteMediaItemFromAlbumSuccess, (state, { id }) => {
    const currentResults = state.paginatedDetailedAlbumItemList?.results || [];
    const updatedResults = currentResults.filter((media) => media.id !== id);

    return {
      ...state,
      paginatedDetailedAlbumItemList: {
        ...state.paginatedDetailedAlbumItemList,
        results: updatedResults,
        total_objects: (state.paginatedDetailedAlbumItemList?.total_objects || 0) - 1,
      },
    };
  }),
  on(MediaGalleryActions.deleteFolderFromStorageSuccess, (state, { id }) => {
    const currentResults = state.paginatedDriveFolderList?.results || [];
    const updatedResults = currentResults.filter((folder: any) => {
      return folder.id !== id;
    });
    return {
      ...state,
      paginatedDriveFolderList: {
        ...state.paginatedDriveFolderList,
        results: updatedResults,
        total_objects: (state.paginatedDriveFolderList?.total_objects || 0) - 1,
      },
    };
  }),
);
export function mediaGalleryReducer(state: MediaGalleryState | undefined, action: Action) {
  return reducer(state, action);
}
