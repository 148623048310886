import { NgModule, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  NgcContentOptions,
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
  NgcCookieConsentService,
  NgcInitializingEvent,
} from 'ngx-cookieconsent';

import { EnvironmentService } from '@offconon/shared/utils/services/environment';

let cookieConfig: NgcCookieConsentConfig = {
  position: 'bottom-left',
  theme: 'edgeless',
  palette: {
    popup: {
      background: '#000000',
      text: '#ffffff',
      link: '#ffffff',
    },
    button: {
      background: '#f1d600',
      text: '#000000',
      border: 'transparent',
    },
  },
  type: 'info',
};

@NgModule({
  imports: [NgcCookieConsentModule.forRoot(cookieConfig)],
})
export class SharedCookieModule {
  private ccService = inject(NgcCookieConsentService);
  private translate = inject(TranslateService);

  private environmentService = inject(EnvironmentService);
  reopen = false;

  constructor() {
    let content: NgcContentOptions = this.ccService?.getConfig()?.content || {};
    let langKey = [
      'This website uses cookies to ensure you get the best experience on our website. By continuing to use this website, you agree to our use of cookies.',
      'Allow cookies',
      'Refuse cookies',
      'Learn more',
      'Cookie Policy',
    ];
    this.translate.stream(langKey).subscribe((langResponse: any) => {
      content.message =
        langResponse[
          'This website uses cookies to ensure you get the best experience on our website. By continuing to use this website, you agree to our use of cookies.'
        ];
      content.allow = langResponse['Allow cookies'];
      content.deny = langResponse['Refuse cookies'];
      content.link = langResponse['Learn more'];
      content.policy = langResponse['Cookie Policy'];
      const currentConfig = this.ccService.getConfig();
      if (currentConfig) {
        currentConfig.content = content;
        // currentConfig.cookie?.domain = this.environmentService.environment.coreDomain;
        this.ccService.destroy();
        this.ccService.init(currentConfig);
        this.ccService.initializing$.subscribe((event: NgcInitializingEvent) => {
          this.reopen = event.status === 'deny';
        });
      }

      this.ccService.initialized$.subscribe(() => {
        if (this.reopen) this.ccService.open();
      });
    });
  }
}
