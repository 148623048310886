<offconon-layout [sideNavData]="sidenavData()">
  <ng-container left>
    @defer (when user()) {
      @if (user()) {
        @defer (on idle) {
          <offconon-personal-business-switcher />
        }
      }
    }
  </ng-container>
  <ng-container center>
    <offconon-layout-search />
    <offconon-language-change-selector
      [type]="'globe'"
      (openLanguageSelectorEvent)="openLang($event)" />
    <offconon-language-change-content
      [langChangeSidebar]="langChangeSidebar"
      (openLanguageSelectorEvent)="openLang($event)" />
  </ng-container>
  <ng-container rightSide>
    <offconon-core-right-panel (openLanguageSelectorEvent)="openLang($event)" />
    @if (user()) {
      @defer (on idle) {
        <offconon-layout-notifications />
      }
    }
    @defer (on idle) {
      <offconon-layout-shopping-cart />
    }
  </ng-container>
</offconon-layout>
