<div class="my-3 flex flex-wrap items-center gap-2">
  <div class="flex">
    <mat-icon fontIcon="folder" />
  </div>
  <ul class="flex flex-wrap gap-2">
    <li
      *ngFor="let folder of selectedChildrenParents.reverse()"
      class="cursor-pointer hover:text-gray-500"
      (click)="selectedIdChange(folder.id)">
      / {{ folder.name }}
    </li>
    <li class="cursor-pointer hover:text-gray-500" (click)="selectedIdChange(currentFolder.id)">
      / {{ currentFolder?.name }}
    </li>
  </ul>
</div>
