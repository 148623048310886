import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';

import { StepsColumnsComponent } from '../helpers/steps-columns/steps-columns.component';

@Component({
  selector: 'offconon-pipeline-flow-common-render',
  templateUrl: './pipeline-flow-common-render.component.html',
  imports: [StepsColumnsComponent, TranslateModule, ButtonModule, CommonModule],
})
export class PipelineFlowCommonRenderComponent {
  @Input({ required: true }) createButton: TemplateRef<any>;
  @Input() parameters: any;
  @Input() pipeline: any;
  @Input({ required: true }) cardTemplate: TemplateRef<any>;

  @Output() dataLoadedEvent = new EventEmitter<any>();

  private router = inject(Router);

  isAccessRights = true;

  selected_pipeline_id: any;

  create() {
    this.router.navigate([this.parameters?.create_link]);
  }
}
