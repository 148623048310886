import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'offconon-folder-tree-item',
  templateUrl: './folder-tree-item.component.html',
  imports: [CommonModule, MatIcon, TranslateModule],
  styles: ``,
})
export class FolderTreeItemComponent {
  @Input() folder: any;
  @Input() selectedId: any;
  @Input() isOpen = false;
  @Input() business_id: number;
  @Input() user_id: number;
  @Input() selected_file_album_id: number;
  @Output() selectedIdEmit = new EventEmitter<any>();
  @Output() updateFoldersEmit = new EventEmitter<any>();

  selectItem(id: any) {
    if (this.selected_file_album_id === id) {
      return;
    }
    this.selectedIdEmit.emit(id);
  }
}
