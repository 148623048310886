<form action="" class="grip w-full max-w-full pb-5" [formGroup]="form" (submit)="submit()">
  <div class="flex flex-col pb-2">
    <div class="flex flex-col gap-2 mb-4 mt-4">
      <p-floatlabel variant="on">
        <input formControlName="name" id="name" type="text" pInputText class="w-full" />
        <label for="name"> {{ 'Name' | translate }} </label>
      </p-floatlabel>
      <small class="block mt-1 leading-6 text-xs">
        {{ 'Enter the name of the Folder.' | translate }} {{ 'Maximum characters:' | translate }} 25
      </small>
    </div>
  </div>

  <div class="flex flex-row-reverse flex-wrap pt-3">
    <div class="flex items-center justify-center">
      <p-button
        icon="pi pi-save"
        iconPos="left"
        type="submit"
        [styleClass]="'w-full'"
        label="{{ 'Save' | translate }}"
        [loading]="loading"
        [disabled]="form.invalid"
        [rounded]="true" />
    </div>
  </div>
</form>
