<div class="flex flex-col">
  <div class="flex flex-row gap-3 p-3">
    <p-button (click)="openFileInput()">{{ 'upload' | translate }}</p-button>
    <p-button [severity]="'success'" (click)="addToEditor()">{{
      'add to editor' | translate
    }}</p-button>
    <p-button [severity]="'danger'" (click)="deleteMedia()">{{ 'delete' | translate }}</p-button>
    <p-select optionValue="code" optionLabel="name" [options]="options" [(ngModel)]="showType" />
  </div>
  <offconon-media-gallery-content [multiple]="multiple" [showType]="showType" />
</div>
<input #fileInput type="file" style="display: none" multiple (change)="onFileSelected($event)" />
