import { DestroyRef, Injectable, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { MenuSettings, getSearchMenuSettings } from 'libs/core/data/stores/search-settings';
import { isUserBusiness } from 'libs/shared/data/stores/auth/auth.reducer';
import { getAuthUser, selectUserBusinessEntity } from 'libs/shared/data/stores/auth/auth.selectors';
import { map } from 'rxjs';

import { UserCommonApplicationProfileSettings } from '@offconon/core-api';
import { AppHelperService } from '@offconon/shared/utils/services/app-helper';

@Injectable({
  providedIn: 'root',
})
export class SideBarService {
  private destroyRef = inject(DestroyRef);
  private store = inject(Store);

  private isBusiness = toSignal<boolean>(
    this.store.select(selectUserBusinessEntity).pipe(
      map((res) => {
        return isUserBusiness(res);
      }),
    ),
  );
  searchMenuSettings = toSignal(this.store.select(getSearchMenuSettings));
  user = toSignal(this.store.select(getAuthUser));

  private appHelperService = inject(AppHelperService);

  menus = computed(() => {
    return [
      {
        name: 'Quick start',
        menuIcon: 'home',
        visible: true,
        elements: [
          {
            link: '/apps',
            icon: 'apps',
            firstLine: 'Applications',
            visible: true,
          },

          {
            link: '/my-apps',
            icon: 'collections_bookmark',
            firstLine: 'My applications',
            visible: !this.isBusiness(),
          },
          {
            link: '/cognitive-erp/workplaces',
            icon: 'badge',
            firstLine: 'Workplaces',
            visible: !this.isBusiness(),
          },

          {
            link: '/communication',
            icon: 'contact_phone',
            firstLine: 'Communication',
            visible: !this.isBusiness(),
          },

          {
            link: '/notifications',
            icon: 'notifications_active',
            firstLine: 'Notifications',
            visible: !this.isBusiness(),
          },

          {
            link: '/academy',
            icon: 'cast_for_education',
            firstLine: 'Academy',
            visible: true,
          },
        ],
      },
      {
        name: 'Shopping',
        menuIcon: 'shopping_cart',
        visible: true,
        elements: [
          {
            link: '/shopping-cart',
            icon: 'add_shopping_cart',
            firstLine: 'Shopping cart',
            visible: true,
          },
          {
            link: '/my-purchases',
            icon: 'fact_check',
            firstLine: 'My purchases',

            visible: !this.isBusiness(),
          },

          {
            link: '/my-offers',
            icon: 'percent',
            firstLine: 'My offers',

            visible: !this.isBusiness(),
          },
          {
            link: '/my-auctions',
            icon: 'gavel',
            firstLine: 'My auctions',
            visible: !this.isBusiness(),
          },
          {
            link: '/search-settings',
            icon: 'content_paste_search',
            firstLine: 'Search settings',
            visible: true,
          },
          {
            link: '/favourites',
            icon: 'bookmark_added',
            firstLine: 'Saved favourites',
            visible: !this.isBusiness(),
          },
          {
            link: '/recently-viewed-ads',
            icon: 'history',
            firstLine: 'Recently viewed ads',
            visible: true,
          },
        ],
      },

      {
        name: 'Business / Workplace',
        menuIcon: 'qr_code',
        visible: !this.isBusiness(),
        elements: [
          {
            link: '/cognitive-erp/workplaces',
            icon: 'badge',
            firstLine: 'Workplaces',
            visible: !this.isBusiness(),
          },
          {
            link: '/cognitive-erp/create',
            icon: 'dashboard_customize',
            firstLine: 'Create',
            visible: !this.isBusiness(),
          },
          {
            link: '/cognitive-erp/workplace-invitations',
            icon: 'person_add',
            firstLine: 'Workplace invitations',
            visible: !this.isBusiness(),
          },
          {
            link: '/cognitive-erp/premium-subscription',
            icon: 'workspace_premium',
            firstLine: 'Premium subscription',
            visible: !this.isBusiness(),
          },
        ],
      },
      {
        name: 'Productivity tools',
        menuIcon: 'category',
        visible: !this.isBusiness(),
        elements: [
          {
            link: '/task-manager',
            icon: 'add_task',
            firstLine: 'Task manager',
            visible: !this.isBusiness(),
          },
          {
            link: '/notes',
            icon: 'edit_note',
            firstLine: 'Notes',
            visible: !this.isBusiness(),
          },

          {
            link: '/calendar',
            icon: 'calendar_month',
            firstLine: 'Calendar',
            visible: !this.isBusiness(),
          },
          {
            link: '/contacts',
            icon: 'contacts',
            firstLine: 'Contacts',
            visible: !this.isBusiness(),
          },
          {
            link: '/email-manager',
            icon: 'mail',
            firstLine: 'Emails',
            visible: !this.isBusiness(),
          },
          {
            link: '/drive',
            icon: 'cloud_upload',
            firstLine: 'Drive',
            visible: !this.isBusiness(),
          },
          {
            link: '/apps/productivity',
            icon: 'screen_search_desktop',
            firstLine: 'More productivity tools',
            visible: !this.isBusiness(),
          },
        ],
      },
      {
        name: 'Communities',
        menuIcon: 'group_add',
        visible: true,
        elements: [
          {
            link: '/activity-stream',
            icon: 'speaker_notes',
            firstLine: 'Activity stream',
            visible: true,
          },
          {
            link: this.appHelperService.getAppConfig(
              UserCommonApplicationProfileSettings.CommunityApplicationTypeEnum.Garden,
            )?.['app_url'],
            icon: 'yard',
            firstLine: this.appHelperService.getAppConfig(
              UserCommonApplicationProfileSettings.CommunityApplicationTypeEnum.Garden,
            )?.['app_brand_name'],
            visible: this.checkMenuVisibility(
              this.searchMenuSettings(),
              UserCommonApplicationProfileSettings.CommunityApplicationTypeEnum.Garden,
              true,
            ),
          },
          {
            link: this.appHelperService.getAppConfig(
              UserCommonApplicationProfileSettings.CommunityApplicationTypeEnum.BusinessNetworking,
            )?.['app_url'],
            icon: 'handshake',
            firstLine: this.appHelperService.getAppConfig(
              UserCommonApplicationProfileSettings.CommunityApplicationTypeEnum.BusinessNetworking,
            )?.['app_brand_name'],
            visible: this.checkMenuVisibility(
              this.searchMenuSettings(),
              UserCommonApplicationProfileSettings.CommunityApplicationTypeEnum.BusinessNetworking,
              true,
            ),
          },
          {
            link: this.appHelperService.getAppConfig(
              UserCommonApplicationProfileSettings.CommunityApplicationTypeEnum.Pets,
            )?.['app_url'],
            icon: 'pets',
            firstLine: this.appHelperService.getAppConfig(
              UserCommonApplicationProfileSettings.CommunityApplicationTypeEnum.Pets,
            )?.['app_brand_name'],
            visible: this.checkMenuVisibility(
              this.searchMenuSettings(),
              UserCommonApplicationProfileSettings.CommunityApplicationTypeEnum.Pets,
              true,
            ),
          },
          {
            link: this.appHelperService.getAppConfig(
              UserCommonApplicationProfileSettings.CommunityApplicationTypeEnum.LanguageExchange,
            )?.['app_url'],
            icon: 'translate',
            firstLine: this.appHelperService.getAppConfig(
              UserCommonApplicationProfileSettings.CommunityApplicationTypeEnum.LanguageExchange,
            )?.['app_brand_name'],
            visible: this.checkMenuVisibility(
              this.searchMenuSettings(),
              UserCommonApplicationProfileSettings.CommunityApplicationTypeEnum.LanguageExchange,
              true,
            ),
          },
          {
            link: '/be-green',
            icon: 'eco',
            firstLine: 'Be GREEN',
            visible: true,
          },

          {
            link: this.appHelperService.getAppConfig(
              UserCommonApplicationProfileSettings.CommunityApplicationTypeEnum.BucketList,
            )?.['app_url'],
            icon: 'checklist_rtl',
            firstLine: this.appHelperService.getAppConfig(
              UserCommonApplicationProfileSettings.CommunityApplicationTypeEnum.BucketList,
            )?.['app_brand_name'],
            visible: this.checkMenuVisibility(
              this.searchMenuSettings(),
              UserCommonApplicationProfileSettings.CommunityApplicationTypeEnum.BucketList,
              true,
            ),
          },

          {
            link: '/apps/communities',
            icon: 'person_search',
            firstLine: 'More communities',
            visible: true,
          },
        ],
      },
      {
        name: 'Marketplaces',
        menuIcon: 'manage_search',
        visible: true,
        elements: [
          {
            link: '/businesses-stores',
            icon: 'storefront',
            firstLine: 'Businesses / Stores',

            activeUrl: 'businesses-stores',
            visible: true,
          },
          {
            link: this.appHelperService.getAppConfig(
              UserCommonApplicationProfileSettings.CommunityApplicationTypeEnum.MarketSpace,
            )?.['app_url'],
            icon: 'category',
            firstLine: this.appHelperService.getAppConfig(
              UserCommonApplicationProfileSettings.CommunityApplicationTypeEnum.MarketSpace,
            )?.['app_brand_name'],
            visible: this.checkMenuVisibility(
              this.searchMenuSettings(),
              UserCommonApplicationProfileSettings.CommunityApplicationTypeEnum.MarketSpace,
              true,
            ),
          },
          {
            link: '/apps/products',
            icon: 'sell',
            firstLine: 'Market of products',

            activeUrl: 'apps',
            visible: true,
          },
          {
            link: '/apps/services',
            icon: 'build_circle',
            firstLine: 'Market of services',

            activeUrl: 'apps',
            visible: true,
          },
          {
            link: '/apps/rent',
            icon: 'event_available',
            firstLine: 'Market of rentable products',

            activeUrl: 'apps',
            visible: true,
          },
          {
            link: '/apps/digital-products',
            icon: 'cloud_download',
            firstLine: 'Market of digital products',

            activeUrl: 'apps',
            visible: true,
          },

          {
            link: '/apps/marketplaces',
            icon: 'screen_search_desktop',
            firstLine: 'More marketplaces',

            activeUrl: 'apps',
            visible: true,
          },
        ],
      },
      {
        name: 'OffConOn Passport',
        menuIcon: 'account_circle',
        visible: !this.isBusiness(),
        elements: [
          {
            link: '/profile/' + this.user()?.username,
            icon: 'person_pin',
            firstLine: 'Profile',
            visible: !this.isBusiness(),
          },

          {
            link: '/me',
            icon: 'assignment_ind',
            firstLine: 'My profile settings',
            visible: !this.isBusiness(),
          },

          {
            link: '/loyalty',
            icon: 'loyalty',
            firstLine: 'Loyalty',
            visible: !this.isBusiness(),
          },
          {
            link: '/partner-office',
            icon: 'meeting_room',
            firstLine: 'Partner office',
            visible: !this.isBusiness(),
          },
          {
            link: '/wallet',
            icon: 'wallet',
            firstLine: 'Wallet',
            visible: !this.isBusiness(),
          },
          {
            link: '/personal-subscription',
            icon: 'local_police',
            firstLine: 'Premium membership',
            visible: !this.isBusiness(),
          },

          {
            link: '/search-settings',
            icon: 'content_paste_search',
            firstLine: 'Search settings',

            visible: !this.isBusiness(),
          },
          {
            link: '/my-subscription',
            icon: 'card_membership',
            firstLine: 'My subscription',

            visible: !this.isBusiness(),
          },
          {
            link: '/traditional-bank-transfer',
            icon: 'clear_all',
            firstLine: 'Traditional bank transfer',

            visible: !this.isBusiness(),
          },
          {
            link: '/invoices',
            icon: 'receipt_long',
            firstLine: 'Invoices',

            visible: !this.isBusiness(),
          },
          {
            link: '/verification',
            icon: 'how_to_reg',
            firstLine: 'Verification',

            visible: !this.isBusiness(),
          },
          {
            link: '/social-connections',
            icon: 'group',
            firstLine: 'Social connections',
            visible: !this.isBusiness(),
          },
          {
            link: '/blocked-users',
            icon: 'person_off',
            firstLine: 'Blocked users',
            visible: !this.isBusiness(),
          },
          {
            link: '/affiliate',
            icon: 'meeting_room',
            firstLine: 'Affiliate',
          },
          {
            link: '/about-us',
            icon: 'meeting_room',
            firstLine: 'About us',
          },

          {
            link: '/hire-us',
            icon: 'meeting_room',
            firstLine: 'Hire us',
          },

          {
            link: '/what-is-offconon',
            icon: 'meeting_room',
            firstLine: 'What is offconon',
          },

          {
            link: '/join-professional-team',
            icon: 'meeting_room',
            firstLine: 'Join our professional team',
          },
        ],
      },
    ];
  });

  checkMenuVisibility(
    menuSettings: MenuSettings[] | undefined,
    type: UserCommonApplicationProfileSettings.CommunityApplicationTypeEnum,
    defaultValue = true,
  ) {
    return menuSettings?.find((item) => item.community_application_type === type)
      ? menuSettings?.find((item) => item.community_application_type === type)?.is_enabled
      : defaultValue;
  }
}
