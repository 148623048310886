import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  inject,
  SimpleChanges,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { PaginatorModule } from 'primeng/paginator';
import { TooltipModule } from 'primeng/tooltip';
import { Subscription } from 'rxjs';

import { PaginatedAlbumTemplateRecursiveList } from '@offconon/core-api';

import { fetchAlbumItems } from '../../../store/media-gallery.actions';
import { selectAlbumItemsWithSelected } from '../../../store/media-gallery.selectors';
import { MediaFolderContentCardComponent } from '../media-folder-content-card/media-folder-content-card.component';

@Component({
  selector: 'offconon-media-folder-content',
  imports: [
    CommonModule,
    TranslateModule,
    PaginatorModule,
    DragDropModule,
    MatIcon,
    TooltipModule,
    ButtonModule,
    MenuModule,
    MediaFolderContentCardComponent,
  ],
  templateUrl: './media-folder-content.component.html',
  styles: ``,
})
export class MediaFolderContentComponent implements OnChanges, OnDestroy, OnInit {
  @Input() multiple = false;
  @Input() newFiles = {};
  @Input() folderList: PaginatedAlbumTemplateRecursiveList | undefined;
  @Input() user_id: number = 0;
  @Input() selectedChildren: any[] = [];
  @Input() selectedChildrenParents: any[] = [];
  @Input() business_id: any = null;
  @Input() album_id: number = 0;
  @Input() showType: 'all' | 'image' | 'video' | 'audio' | 'document' | 'zip' | 'other' = 'all';
  @Output() selectedItemEmit = new EventEmitter<any>();
  @Output() selectedFolderIdEmit = new EventEmitter<any>();
  @Output() selectedFolderEmit = new EventEmitter<any>();
  @Output() EditImageEmit = new EventEmitter<any>();

  @ViewChild('moreItem', { static: false })
  private loadMoreDiv: ElementRef<HTMLDivElement>;
  isLoadMoreDivScrolledIntoView: boolean;

  items: MenuItem[];
  private store = inject(Store);
  private translateService = inject(TranslateService);
  pageSize = 10;
  page: number = 1;
  loading = false;
  private paginatedSubscription: Subscription;
  public datas: any;
  public results: any;
  public selectedIds: number[] = [];
  public selectedItem: any[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['newFiles']) {
      if (this.showType === 'all') {
        this.store.dispatch(
          fetchAlbumItems({
            page: this.page,
            pageSize: this.pageSize,
            searchJson: {
              album_id: this.album_id,
            },
          }),
        );
      } else {
        this.store.dispatch(
          fetchAlbumItems({
            page: this.page,
            pageSize: this.pageSize,
            searchJson: {
              media_file__content_upload_type: this.showType,
              album_id: this.album_id,
            },
          }),
        );
      }
    }
    if (
      (changes['showType'] && this.album_id) ||
      (changes['album_id'] && changes['album_id'].currentValue)
    ) {
      this.page = 1;
      this.results = [];
      this.selectedIds = [];
      this.selectedItem = [];
      if (this.showType === 'all') {
        this.store.dispatch(
          fetchAlbumItems({
            page: this.page,
            pageSize: this.pageSize,
            searchJson: {
              album_id: this.album_id,
            },
          }),
        );
      } else {
        this.store.dispatch(
          fetchAlbumItems({
            page: this.page,
            pageSize: this.pageSize,
            searchJson: {
              media_file__content_upload_type: this.showType,
              album_id: this.album_id,
            },
          }),
        );
      }
    }
  }

  ngOnDestroy(): void {
    document.removeEventListener('scroll', this.scroll, true);
    if (this.paginatedSubscription) {
      this.paginatedSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.datas = [];
    this.results = [];
    document.addEventListener('scroll', this.scroll, true);
    this.paginatedSubscription = this.store
      .select(selectAlbumItemsWithSelected)
      .subscribe((data) => {
        this.datas = data;
        if (data?.results) {
          if (this.page === 1) {
            this.results = data?.results;
          } else {
            data?.results.forEach((result: any) => {
              let index = 0;
              index = this.results.findIndex((item: any) => item.id === result.id);

              if (index === -1) {
                this.results.push(result);
              } else {
                this.results[index] = result;
              }
            });
          }
        }
      });
  }

  resetSelected(event: any) {
    if (event) {
      this.selectedItem = [];
      this.selectedIds = [];
      this.results.forEach((element: any) => {
        const isSelected = element.selected;
        if (isSelected) {
          element.selected = false;
        }
      });
      this.selectedItemEmit.emit(this.selectedItem);
    }
  }

  onDragStart(event: DragEvent, media: any) {
    if (media.media_file_type) {
      event.dataTransfer?.setData('mediaId', media.id);
    } else {
      event.dataTransfer?.setData('albumId', media.id);
    }

    event.dataTransfer?.setData('mediaType', media.media_file_type);
  }

  scroll = (e: any) => {
    this.checkIfInView();
  };

  checkIfInView() {
    if (!this.loading) {
      const rect = this.loadMoreDiv?.nativeElement.getBoundingClientRect();
      const isInView = rect?.top >= 0 && rect.bottom <= window.innerHeight;

      this.isLoadMoreDivScrolledIntoView = isInView;
      if (isInView && !this.loading) {
        this.loadMore();
      }
    }
  }
  selectedFolderId(item: any) {
    this.selectedFolderEmit.emit(item);
    this.selectedFolderIdEmit.emit(item.id);
  }
  editImage(url: any) {
    this.EditImageEmit.emit(url);
  }
  loadMore() {
    this.loading = true;
    if (this.page > (this.datas?.total_pages || 0)) {
      return;
    }
    this.page++;
    if (this.showType === 'all') {
      this.store.dispatch(
        fetchAlbumItems({
          page: this.page,
          pageSize: this.pageSize,
          searchJson: {
            album_id: this.album_id,
          },
        }),
      );
    } else {
      this.store.dispatch(
        fetchAlbumItems({
          page: this.page,
          pageSize: this.pageSize,
          searchJson: {
            media_file__content_upload_type: this.showType,
            album_id: this.album_id,
          },
        }),
      );
    }
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  paginationChanged(event: any) {
    this.page = event.page + 1;
    if (this.showType === 'all') {
      this.store.dispatch(
        fetchAlbumItems({
          page: this.page,
          pageSize: this.pageSize,
          searchJson: {
            album_id: this.album_id,
          },
        }),
      );
    } else {
      this.store.dispatch(
        fetchAlbumItems({
          page: this.page,
          pageSize: this.pageSize,
          searchJson: {
            media_file__content_upload_type: this.showType,
            album_id: this.album_id,
          },
        }),
      );
    }
  }

  toggleSelection(id: number): void {
    const index = this.results.findIndex((item: any) => item.id === id);

    if (index !== -1) {
      const isSelected = this.results[index].selected;
      if (this.multiple) {
        this.results[index].selected = !isSelected;

        if (this.results[index].selected) {
          this.selectedIds.push(id);
          this.selectedItem.push(this.results[index]);
        } else {
          this.selectedIds = this.selectedIds.filter((itemId) => itemId !== id);
          this.selectedItem = this.selectedItem.filter((item: any) => item.id !== id);
        }
      } else {
        this.selectedItem = [];

        if (isSelected) {
          this.results[index].selected = false;
          this.selectedIds = this.selectedIds.filter((itemId) => itemId !== id);
        } else {
          this.results[index].selected = true;
          this.selectedIds = [id];
          this.selectedItem = [this.results[index]];
        }

        if (this.selectedIds.length > 0) {
          this.results.forEach((item: any) => {
            if (!this.selectedIds.includes(item.id)) {
              item.selected = false;
            }
          });
        }
      }
    }

    this.selectedItemEmit.emit(this.selectedItem);
  }
}
