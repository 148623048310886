import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  MEDIA_GALLERY_FEATURE_KEY,
  MediaGalleryState,
  mediaGalleryAdapter,
  PaginatedDetailedAlbumItemListWithSelected,
  DetailedAlbumItemWithSelected,
} from './media-gallery.reducer';

// Lookup the 'MediaGallery' feature state managed by NgRx
export const selectMediaGalleryState =
  createFeatureSelector<MediaGalleryState>(MEDIA_GALLERY_FEATURE_KEY);

const { selectAll, selectEntities } = mediaGalleryAdapter.getSelectors();

export const selectAllMediaGallery = createSelector(
  selectMediaGalleryState,
  (state: MediaGalleryState) => selectAll(state),
);

export const selectMediaGalleryEntities = createSelector(
  selectMediaGalleryState,
  (state: MediaGalleryState) => selectEntities(state),
);

export const selectSelectedMediaIds = createSelector(
  selectMediaGalleryState,
  (state: MediaGalleryState) => state.selectedMediaIds,
);
export const selectPaginatedDetailedAlbumItemList = createSelector(
  selectMediaGalleryState,
  (state: MediaGalleryState) => state.paginatedDetailedAlbumItemList,
);
export const selectPaginatedAlbumItemList = createSelector(
  selectMediaGalleryState,
  (state: MediaGalleryState) => state.paginatedDetailedAlbumItemList,
);
export const selectFolderList = createSelector(
  selectMediaGalleryState,
  (state: MediaGalleryState) => state.paginatedFolderList,
);
export const selectDriveFolderList = createSelector(
  selectMediaGalleryState,
  (state: MediaGalleryState) => state.paginatedDriveFolderList,
);
export const selectStorageDetails = createSelector(
  selectMediaGalleryState,
  (state: MediaGalleryState) => state.storageDetails,
);
export const selectRestorableItems = createSelector(
  selectMediaGalleryState,
  (state: MediaGalleryState) => state.restorableItems,
);
export const selectRestoredItems = createSelector(
  selectMediaGalleryState,
  (state: MediaGalleryState) => state.restoredItems,
);
export const selectDetailedAlbumItemsWithSelected = createSelector(
  selectPaginatedDetailedAlbumItemList,
  selectSelectedMediaIds,
  (paginatedList, selectedIds): PaginatedDetailedAlbumItemListWithSelected | undefined => {
    if (!paginatedList) return undefined;

    return {
      ...paginatedList,
      results:
        paginatedList.results?.map((item) => ({
          ...item,
          selected: item.id ? selectedIds.includes(item.id) : false,
        })) ?? [],
    };
  },
);
export const selectAlbumItemsWithSelected = createSelector(
  selectPaginatedAlbumItemList,
  selectSelectedMediaIds,
  (paginatedList, selectedIds): PaginatedDetailedAlbumItemListWithSelected | undefined => {
    if (!paginatedList) return undefined;

    return {
      ...paginatedList,
      results:
        paginatedList.results?.map((item) => ({
          ...item,
          selected: item.id ? selectedIds.includes(item.id) : false,
        })) ?? [],
    };
  },
);

export const selectSelectedMediaItems = createSelector(
  selectPaginatedDetailedAlbumItemList,
  selectSelectedMediaIds,
  (paginatedList, selectedIds): DetailedAlbumItemWithSelected[] => {
    if (!paginatedList || !paginatedList.results) return [];

    return paginatedList.results
      .filter((item) => item.id && selectedIds.includes(item.id))
      .map((item) => ({
        ...item,
        selected: true,
      }));
  },
);
