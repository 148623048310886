import { GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  ApplicationConfig,
  ErrorHandler,
  LOCALE_ID,
  enableProdMode,
  importProvidersFrom,
  inject,
  provideZoneChangeDetection,
  provideAppInitializer,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, UrlHandlingStrategy, provideRouter } from '@angular/router';
import { JWT_OPTIONS } from '@auth0/angular-jwt';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as fromSearchSettings from 'libs/core/data/stores/search-settings/search-settings.reducer';
import { SEARCH_SETTINGS_FEATURE_KEY } from 'libs/core/data/stores/search-settings/search-settings.reducer';
import { AUTH_FEATURE_KEY, authReducer } from 'libs/shared/data/stores/auth/auth.reducer';
import { variables } from 'libs/shared/utils/variables/variables';
import { localStorageSync } from 'ngrx-store-localstorage';
import { MessageService } from 'primeng/api';

import { AuthInterceptor } from '@offconon/core/features/auth';
import { BASE_PATH } from '@offconon/core-api';
import { SharedCookieModule } from '@offconon/shared/features/cookie';
import { LAYOUT_FEATURE_KEY, LayoutEffects, layoutReducer } from '@offconon/shared/features/layout';
import { COUNTRY_FEATURE_KEY } from '@offconon/shared/ui/form-elements/country-select-list';
import { CustomRouteReuseStrategy } from '@offconon/shared/utils/services/custom-route-reuse-strategy';
import { GlobalErrorHandlerService } from '@offconon/shared/utils/services/global-error-handler';
import { LanguageService } from '@offconon/shared/utils/services/language';

import { EnvironmentService } from '@offconon/shared/utils/services/environment';

import { SharedUtilsTranslateModule } from '@offconon/shared/utils/translate';
import {
  initLanguageFactory,
  CustomUrlHandlingStrategy,
} from '@offconon/shared/utils/services/custom-url-handling-strategy';

import { BASE_PATH as USER_BASE_PATH } from '@offconon/user-api';

import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [AUTH_FEATURE_KEY, SEARCH_SETTINGS_FEATURE_KEY, COUNTRY_FEATURE_KEY, LAYOUT_FEATURE_KEY],
    rehydrate: true,
    checkStorageAvailability: true,
  })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

if (environment.production) {
  enableProdMode();
}
export const appConfig: ApplicationConfig = {
  providers: [
    // provideClientHydration(withEventReplay()),
    provideAppInitializer(() => {
      const initializerFn = initLanguageFactory(inject(CustomUrlHandlingStrategy));
      return initializerFn();
    }),
    { provide: UrlHandlingStrategy, useClass: CustomUrlHandlingStrategy },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes),
    { provide: BASE_PATH, useValue: environment.coreApi },
    { provide: USER_BASE_PATH, useValue: environment.coreApi },

    importProvidersFrom(
      SharedUtilsTranslateModule,

      StoreDevtoolsModule.instrument({
        maxAge: 25,
        logOnly: environment.production,
        connectInZone: true,
      }),
      StoreModule.forRoot([], { metaReducers }),
      StoreModule.forFeature(AUTH_FEATURE_KEY, authReducer),
      StoreModule.forFeature(LAYOUT_FEATURE_KEY, layoutReducer),
      StoreModule.forFeature(
        fromSearchSettings.SEARCH_SETTINGS_FEATURE_KEY,
        fromSearchSettings.searchSettingsReducer,
      ),
      EffectsModule.forFeature([LayoutEffects]),
      EffectsModule.forRoot([]),
      !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
      SharedCookieModule,
    ),
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleLoginProviderKey),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    provideAppInitializer(() => {
      const initializerFn = (() => {
        const environmentService = inject(EnvironmentService);
        return () => environmentService.setEnvironment(environment);
      })();
      return initializerFn();
    }),
    {
      provide: LOCALE_ID,
      deps: [LanguageService],
      useFactory: (languageService: LanguageService) => {
        return languageService.getLanguageAndRegisterLocaleData();
      },
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    { provide: variables.websocketAipUrlKey, useValue: environment.coreWebsocket },
    MessageService,

    LanguageService,
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideAnimations(),
  ],
};
