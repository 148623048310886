import { CommonModule } from '@angular/common';
import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
  FormControl,
} from '@angular/forms';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { SelectModule } from 'primeng/select';

import { Album, DriveService } from '@offconon/core-api';
import { SiteLanguageService } from '@offconon/shared/utils/services/site-language';

@Component({
  selector: 'offconon-media-folder-form-common',
  templateUrl: './media-folder-form-common-component.html',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    SelectModule,
    ButtonModule,
    TranslateModule,
    FloatLabelModule,
    CommonModule,
  ],
})
export class MediaFolderFormCommonComponent implements OnInit {
  private fb = inject(FormBuilder);
  private translateService = inject(TranslateService);
  private messageService = inject(MessageService);
  private driveService = inject(DriveService);
  private siteLanguageService = inject(SiteLanguageService);

  @Input() user_id: any;
  @Input() business_id: any;
  @Input() album_id: any;
  @Input() edit_id: any;
  @Input() name: any;
  @Output() formSavedEvent = new EventEmitter<any>();

  private destroyRef = inject(DestroyRef);

  loading = false;
  form: FormGroup;
  media_share_option: any;
  actual_language_id = 71;
  language_parameters: any = {};

  ngOnInit() {
    this.language_parameters = {
      translation_supported: true,
    };

    this.media_share_option = [
      {
        name: this.translateService.instant('Private'),
        code: Album.MediaShareEnum.Nobody,
      },
      {
        name: this.translateService.instant('Public'),
        code: Album.MediaShareEnum.Anybody,
      },
      {
        name: this.translateService.instant('Those who follow me'),
        code: Album.MediaShareEnum.ThoseFollowMe,
      },
      {
        name: this.translateService.instant('Who I follow'),
        code: Album.MediaShareEnum.WhoIFollow,
      },
      {
        name:
          this.translateService.instant('Those who follow me') +
          ' | ' +
          this.translateService.instant('Who I follow'),
        code: Album.MediaShareEnum.Both,
      },
    ];

    this.actual_language_id = this.siteLanguageService.actualSiteLanguageId();

    this.form = this.fb.group({
      id: [this.edit_id || null],
      media_share: [Album.MediaShareEnum.Nobody, [Validators.required]],
      business_id: [this.business_id],
      name: [
        this.name || '',
        [Validators.maxLength(25), Validators.minLength(2), Validators.required],
      ],
      description: ['', [Validators.maxLength(85), Validators.minLength(2)]],
      language_id: [71],
    });
  }

  get languageFormControl() {
    return this.form.get('language_id') as FormControl;
  }

  submit() {
    //this.loading = true;
    let payload: any = {};
    payload = this.form.value;
    if (payload.description === '') {
      payload.description = null;
    }
    if (!payload.id) {
      if (this.album_id) {
        //payload.album_id = this.album_id;
        payload.parent_id = this.album_id;
      }
      //payload.parent_id = 1;
      this.driveService
        .driveAlbumCreate(payload)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (result: Album | any) => {
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('Successful'),
              detail: this.translateService.instant('Your data has been successfully saved.'),
            });
            this.formSavedEvent.emit(result);
          },
          complete: () => {
            this.loading = false;
          },
        });
    } else {
      payload.comment = '';
      payload.description = '';
      this.driveService
        .driveAlbumUpdate(payload)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (result: Album | any) => {
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('Successful'),
              detail: this.translateService.instant('Your data has been successfully saved.'),
            });
            this.formSavedEvent.emit(result);
          },
          complete: () => {
            this.loading = false;
          },
        });
    }
  }
}
