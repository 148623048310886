import { isPlatformBrowser, Location } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DefaultUrlSerializer, UrlHandlingStrategy, UrlTree } from '@angular/router';
import { variables } from 'libs/shared/utils/variables/variables';

import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root',
})
export class CustomUrlHandlingStrategy implements UrlHandlingStrategy {
  private location = inject(Location);
  private platformId = inject<Object>(PLATFORM_ID);

  private serializer = new DefaultUrlSerializer();
  private languageService = inject(LanguageService);

  shouldProcessUrl(): boolean {
    return true;
  }

  extract(url: UrlTree): UrlTree {
    let fullPath = url.toString();
    if (url.toString() === '/') {
      fullPath = this.location.path();
    }
    const path = fullPath.endsWith('/') ? fullPath.slice(0, -1) : fullPath;

    const segments = path.split('/').filter((segment) => segment.length > 0);

    const firstSegment = segments[0];
    const hasLangCode = this.languageService.isSupportedLanguage(firstSegment);

    if (!hasLangCode) {
      const currentLanguage = this.languageService.getLanguage();
      return this.serializer.parse(`/${currentLanguage}${path}`);
    } else {
      if (segments.length > 1 && this.languageService.isSupportedLanguage(segments[1])) {
        segments.splice(1, 1);
        return this.serializer.parse('/' + segments.join('/'));
      }
    }

    return this.serializer.parse(fullPath);
  }

  merge(newUrlPart: UrlTree): UrlTree {
    return newUrlPart;
  }
  private extractLanguageCode(url: string): string | null {
    const urlParts = url.split('/').filter((part) => part !== '');
    const potentialLangCode = urlParts[0];
    return this.languageService.isSupportedLanguage(potentialLangCode) ? potentialLangCode : null;
  }

  initializeLanguage(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (!localStorage.getItem(variables.languageCodeStorageKey)) {
        localStorage.setItem(variables.languageCodeStorageKey, variables.defaultLanguage);
      }
    }
    const path = this.location.path();
    const langCode = this.extractLanguageCode(path);

    if (langCode) {
      this.languageService.setLanguage(langCode);
    } else {
      const currentLang = this.languageService.getLanguage();
      this.location.go(`/${currentLang}${path}`);
    }
  }
}
export function initLanguageFactory(strategy: CustomUrlHandlingStrategy) {
  return () => {
    strategy.initializeLanguage();
    return Promise.resolve();
  };
}
