<div>
  <div class="my-3">
    <ul class="flex flex-col gap-2">
      <li *ngFor="let folder of folderList?.results" class="cursor-pointer flex items-center">
        @if (!folder.album_template?.parent_id) {
          <offconon-folder-tree-item
            [folder]="folder"
            [user_id]="user_id"
            [selected_file_album_id]="selected_file_album_id"
            [business_id]="business_id"
            [selectedId]="selectedId"
            (selectedIdEmit)="selectedIdChange($event)" />
        }
      </li>
    </ul>
    <button
      data-testid="move_file__btn"
      pButton
      icon="pi pi-send"
      class="btn btn-secondary mt-4"
      label="Move"
      [disabled]="!selectedId"
      (click)="moveFile()"></button>
  </div>
</div>
