<div class="columns-2xs columns-break space-y-4">
  @for (media of paginatedDetailedAlbumItemList()?.results; track media.id) {
    <div
      class="relative cursor-pointer transition-all duration-200 h-fit"
      [class.ring-4]="media.selected"
      [class.ring-blue-500]="media.selected"
      [class.hover:opacity-80]="!media.selected"
      (click)="toggleSelection(media.id || 0)">
      @switch (media.media_file_type) {
        @case ('image') {
          <img alt="Selectable" class="" [src]="media.media_file_url" />
        }
        @case ('video') {
          <div class="break-words">
            <video controls autoplay muted>
              <source type="video/mp4" [src]="media.media_file_url" />
              <source type="video/avi" [src]="media.media_file_url" />
              <source type="video/webm" [src]="media.media_file_url?.replace('.mp4', '.webm')" />
              <source type="video/ogg" [src]="media.media_file_url?.replace('.mp4', '.ogv')" />
              {{ 'Your browser does not support the video tag.' | translate }}
            </video>
            <span>{{ media.media_file_url }}</span>
          </div>
        }
        @case ('audio') {
          <div class="break-words">
            <audio
              alt="Selectable"
              controls
              [src]="media.media_file_url"
              [muted]="media.selected"></audio>
            <span>{{ media.media_file_url }}</span>
          </div>
        }
        @case ('document') {
          <div class="break-words">
            <span>{{ media.media_file_url }}</span>
          </div>
        }
      }
      @if (media.selected) {
        <div class="absolute top-2 right-2 bg-blue-500 rounded-full p-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round">
            <polyline points="20 6 9 17 4 12" />
          </svg>
        </div>
      }
    </div>
  } @empty {
    <div>No media items available.</div>
  }
</div>
