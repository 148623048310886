import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PIPELINE_FEATURE_KEY, PipelineState, pipelineAdapter } from './pipeline.reducer';

// Lookup the 'Pipeline' feature state managed by NgRx
export const selectPipelineState = createFeatureSelector<PipelineState>(PIPELINE_FEATURE_KEY);

const { selectAll, selectEntities } = pipelineAdapter.getSelectors();

export const selectPipelineError = createSelector(
  selectPipelineState,
  (state: PipelineState) => state.error,
);

export const selectAllPipeline = createSelector(selectPipelineState, (state: PipelineState) =>
  selectAll(state),
);

export const selectPipelineEntities = createSelector(selectPipelineState, (state: PipelineState) =>
  selectEntities(state),
);
export const selectPipeLineSteps = createSelector(selectPipelineState, (state) => state.pipeline);

export const selectPaginatedPipelineList = createSelector(
  selectPipelineState,
  (state) => state.pipeline,
);
export const selectStepsLoaded = createSelector(selectPipelineState, (state) => state.stepsLoaded);
