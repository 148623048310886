import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  inject,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { PaginatorModule } from 'primeng/paginator';
import { TooltipModule } from 'primeng/tooltip';

import { MediaFolderFormCommonComponent } from '@offconon/shared/ui/media-album-form-common';

import {
  deleteSelectedFolderFromStorage,
  fetchDriveFolders,
} from '../../../store/media-gallery.actions';
import { FolderItemComponent } from './folder-item.component';

@Component({
  selector: 'offconon-folder-list',
  providers: [ConfirmationService],
  imports: [
    CommonModule,
    TranslateModule,
    PaginatorModule,
    MediaFolderFormCommonComponent,
    FolderItemComponent,
    MatIcon,
    TooltipModule,
    ConfirmDialog,
    ButtonModule,
    DividerModule,
    DialogModule,
  ],
  templateUrl: './folder-list.component.html',
  styles: ``,
})
export class FolderListComponent implements OnChanges {
  @Input() multiple = false;
  @Input() user_id: number = 0;
  @Input() folderList: any | undefined;
  @Input() currentFolder: any;
  @Input() business_id: number;
  @Input() selected_folder_id: number;
  @Input() selectedChildrenParents: any[] = [];
  @Output() selectedIdChangeEmit = new EventEmitter<any>();
  @Output() selectedChildrenChangeEmit = new EventEmitter<any>();

  private store = inject(Store);
  private translateService = inject(TranslateService);
  private confirmationService = inject(ConfirmationService);
  private messageService = inject(MessageService);

  pageSize = 100;
  selectedId: any = undefined;
  addFolder = false;
  editFolder = false;
  rootFolders: any;

  foldersWithChildren: any[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    this.rootFolders = this.folderList?.results?.filter((folder: any) => !folder.parent_id);
    if (this.selected_folder_id) {
      this.selectedIdChange(this.selected_folder_id);
    } else {
      if (this.rootFolders && this.rootFolders.length > 0) {
        setTimeout(() => {
          this.selectedIdChange(this.rootFolders[0].id);
        }, 1000);
      }
    }
    if (changes['showType']) {
      this.store.dispatch(
        fetchDriveFolders({
          page: 1,
          pageSize: this.pageSize,
          searchJson: {
            user_id: this.user_id,
            business_id: this.business_id,
          },
        }),
      );
    }
  }

  deleteFolder() {
    const id = this.selectedId || 0;
    if (id > 0) {
      this.confirmationService.confirm({
        message: this.translateService.instant('Are you sure you want to delete this?'),
        header: this.translateService.instant('Attention'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.store.dispatch(deleteSelectedFolderFromStorage({ id: id }));
          this.messageService.add({
            severity: 'info',
            summary: this.translateService.instant('Successful'),
            detail: this.translateService.instant('Your request has been successfully completed.'),
          });
          this.updateFolders();
        },
        key: 'deletefolderDialog',
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('Error'),
        detail: this.translateService.instant('You must select an item to delete.'),
      });
    }
  }

  selectedIdChange(event: any) {
    //const results = this.folderList?.results || [];
    //const filteredFolders = this.findChildrenByParentId(results, event);
    this.selectedId = event;
    //this.selectedChildrenChangeEmit.emit(filteredFolders);
    this.selectedIdChangeEmit.emit(event);
  }
  closeDialog() {
    this.addFolder = false;
  }
  closeEditDialog() {
    this.editFolder = false;
  }
  updateFolders() {
    this.store.dispatch(
      fetchDriveFolders({
        page: 1,
        pageSize: this.pageSize,
        searchJson: {
          user_id: this.user_id,
          business_id: this.business_id,
        },
      }),
    );
  }
  savedForm(event: any) {
    this.store.dispatch(
      fetchDriveFolders({
        page: 1,
        pageSize: this.pageSize,
        searchJson: {
          user_id: this.user_id,
          business_id: this.business_id,
        },
      }),
    );
    setTimeout(() => {
      this.addFolder = false;
      this.editFolder = false;
    }, 2000);
  }
}
