import { createAction, props } from '@ngrx/store';

import { Pipeline } from '@offconon/core-api';
import { PipelineStep } from '@offconon/core-api';
import { PostPipelineStepRequest } from '@offconon/core-api';

import { PipelineEntity } from './pipeline.models';

export const initPipeline = createAction('[Pipeline Page] Init');

export const loadPipelineSuccess = createAction(
  '[Pipeline/API] Load Pipeline Success',
  props<{ pipeline: PipelineEntity[] }>(),
);

export const loadPipelineFailure = createAction(
  '[Pipeline/API] Load Pipeline Failure',
  props<{ error: any }>(),
);

export const fetchPipelineSuccess = createAction(
  '[Pipeline/API] Fetch Steps Success',
  props<{ pipeline?: Pipeline }>(),
);

export const addStepSuccess = createAction(
  '[Pipeline/API] Add Step Success',
  props<{ pipelineStep: PipelineStep }>(),
);
export const editStep = createAction(
  '[Pipeline/API] Edit Step',
  props<{ postPipelineStepRequest: PostPipelineStepRequest }>(),
);

export const deleteStep = createAction('[Pipeline/API] Delete Step', props<{ id: number }>());

export const deleteStepSuccess = createAction(
  '[Pipeline/API] Delete Step Success',
  props<{ id: number }>(),
);

export const editStepSuccess = createAction(
  '[Pipeline/API] Edit Step Success',
  props<{ pipelineStep: PipelineStep }>(),
);
export const fetchPipeline = createAction(
  '[Pipeline/API] Fetch Steps',
  props<{
    page?: number;
    pageSize?: number;
    searchJson?: {
      id?: number;
      name?: string;
      description?: string;
      service_type?: Array<
        | 'Pipeline'
        | 'Prospect'
        | 'Lead'
        | 'Offer'
        | 'Deal'
        | 'Sales Team'
        | 'Employee'
        | 'Recruiting'
        | 'Invitation'
      >;
      service_id?: number;
      user_id?: number;
      co_worker_id?: number;
      business_id?: number;
    };
  }>(),
);
export const addStep = createAction(
  '[Pipeline/API] Add Step',
  props<{ postPipelineStepRequest: PostPipelineStepRequest }>(),
);
export const setLoaded = createAction('[Pipeline/API] Set Loaded', props<{ loaded: boolean }>());
