import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { Router } from '@angular/router';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isUserBusiness } from 'libs/shared/data/stores/auth/auth.reducer';
import { selectUserBusinessEntity, getAuthUser } from 'libs/shared/data/stores/auth/auth.selectors';
import { MenuItem, MessageService } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { Drawer, DrawerModule } from 'primeng/drawer';
import { MenuModule } from 'primeng/menu';
import { TooltipModule } from 'primeng/tooltip';
import { map } from 'rxjs';

import { AuthService } from '@offconon/core/features/auth';
import { LoginResponse } from '@offconon/core-api';
import { MonogramComponent } from '@offconon/shared/ui/monogram';
import { ThemeService } from '@offconon/shared/ui/theme-switch';

import { StartComponent } from './../start.component';
@Component({
  imports: [
    DrawerModule,
    AvatarModule,
    ButtonModule,
    MatIcon,
    TranslateModule,
    TooltipModule,
    MonogramComponent,
    MenuModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    StartComponent,
  ],
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      @media (min-width: 768px) {
        #offconon-layout__rightmenu {
          right: calc(100vw - 500px) !important;
        }
      }
    `,
  ],
  selector: 'offconon-core-right-panel',
  templateUrl: './core-right-panel.component.html',
})
export class CoreRightPanelComponent implements OnInit {
  private router = inject(Router);
  private messageService = inject(MessageService);

  @Output() openLanguageSelectorEvent = new EventEmitter<any>();

  @ViewChild('infoSideBarRef') infoSideBarRef!: Drawer;

  infoSideBar = false;
  isMobileView = false;

  rightItems: MenuItem[] | any;

  private store$ = inject(Store);
  private authenticationService = inject(AuthService);
  private translateService = inject(TranslateService);
  themeService = inject(ThemeService);

  entity = this.store$.select(getAuthUser);
  isUserEntity = true;

  currentUser: any;
  buyer_business_id: any;

  public user: LoginResponse | null = null;
  userBusinessEntity = toSignal(
    this.store$.select(selectUserBusinessEntity).pipe(
      map((res) => {
        if (isUserBusiness(res)) {
          this.isUserEntity = true;
          return {
            ...res,
            name: res.business_mention_name,
            label: 'Business access',
            avatar_path: res.business_avatar_path,
          };
        } else {
          this.isUserEntity = false;
          return {
            ...res,
            name: res?.full_name,
            label: 'Personal access',
            business_id: undefined,
          };
        }
      }),
    ),
  );

  ngOnInit(): void {
    this.themeService.mobileView.subscribe((isMobileView) => {
      this.isMobileView = isMobileView;
    });

    this.store$.select(getAuthUser).subscribe((user: any) => {
      this.user = user;
    });

    this.renderModuleNavigatorMenu(this.user);
  }

  showInformation() {
    this.infoSideBar = !this.infoSideBar;
  }

  openLang(state: any) {
    this.openLanguageSelectorEvent.emit(state);
    this.infoSideBar = false;
  }

  renderModuleNavigatorMenu(user_data: any) {
    this.rightItems = [
      {
        label: this.translateService.instant('Options'),
        items: [
          {
            label: this.translateService.instant('Shopping cart'),
            visible: true,
            icon: 'shopping_cart',
            link: '/shopping-cart',
          },
          {
            label: this.translateService.instant('Search settings'),
            visible: true,
            icon: 'manage_search',
            link: '/search-settings',
          },
          {
            label: this.translateService.instant('Profile'),
            visible: user_data ? true : false,
            icon: 'person_pin',
            commandname: 'profileredirect',
          },
          {
            label: this.translateService.instant('My profile settings'),
            visible: user_data ? true : false,
            icon: 'assignment_ind',
            link: '/me',
          },

          {
            label: this.translateService.instant('Logout'),
            visible: user_data ? true : false,
            icon: 'logout',
            text_color: '',
            commandname: 'logout',
          },

          {
            label: this.translateService.instant('Login'),
            visible: user_data ? false : true,
            icon: 'login',
            link: '/login',
          },
          {
            label: this.translateService.instant('Sign up'),
            visible: user_data ? false : true,
            icon: 'how_to_reg',
            link: '/auth/registration',
          },
        ],
      },

      {
        label: this.translateService.instant('Informations'),
        items: [
          {
            label: this.translateService.instant('Academy'),
            visible: true,
            icon: 'cast_for_education',
            commandname: 'academy',
          },

          {
            label: this.translateService.instant('Help desk'),
            icon: 'support_agent',
            visible: true,
            commandname: 'academy',
          },
        ],
      },
    ];
  }

  commandSelector(command: any) {
    switch (command) {
      case 'academy': {
        this.openAcademy();
        break;
      }

      case 'logout': {
        this.logout();
        window.location.reload();
        break;
      }
      case 'profileredirect': {
        this.redirectProfile();
        break;
      }
    }
  }

  openSubPage(url: any) {
    this.infoSideBar = false;
    this.router.navigate([url]);
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/']);
  }

  redirectProfile() {
    if (this.user) {
      this.router.navigate(['/profile/' + this.user?.username]);
    } else {
      this.router.navigate(['/login']);
    }
  }

  redirectWorkplace() {
    if (this.user) {
      this.router.navigate(['/cognitive-erp/workplaces']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  openAcademy() {
    window.open('/academy', '_blank');
  }
}
