<div
  class="w-96 sm:w-96 md:w-96 h-12 rounded-lg"
  [ngStyle]="{
    'background-color': step_stage_data?.background_color
      ? step_stage_data?.background_color
      : '#0081c7'
  }">
  <div class="mb-1 flex items-center justify-between cursor-pointer">
    <div class="flex items-center m-2">
      <div class="ml-1">
        <span class="block mb-1 mt-1 text-sm white-space-nowrap font-bold">
          <span
            class="cursor-move"
            [ngStyle]="{
              color: step_stage_data?.text_color ? step_stage_data?.text_color : '#ffffff'
            }">
            {{ step_stage_data.name }}
          </span>
        </span>
      </div>
    </div>
    <div>
      <button
        pButton
        icon="pi pi-ellipsis-v"
        class="p-button-text p-button-plain p-button-rounded"
        [ngStyle]="{
          color: step_stage_data?.text_color ? step_stage_data?.text_color : '#ffffff'
        }"
        (click)="menu.toggle($event)"></button>
      <p-menu #menu [appendTo]="'body'" [popup]="true" [model]="items" />
    </div>
  </div>
</div>

<p-dialog
  header="{{ 'Description' | translate }}"
  [modal]="true"
  [closable]="true"
  [style]="{ width: '50rem' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [(visible)]="descVisible">
  @if (step_stage_data.description) {
    <p class="mb-5 leading-6">{{ step_stage_data.description }}</p>
  } @else {
    <p class="mb-5 leading-6 text-red-500">
      {{ 'No information. You did not setup this section.' | translate }}
    </p>
  }
</p-dialog>

@defer (when step_full_bar) {
  <p-drawer
    [fullScreen]="false"
    [baseZIndex]="10000"
    [modal]="true"
    [appendTo]="'body'"
    [styleClass]="'surface-section'"
    [showCloseIcon]="false"
    [(visible)]="step_full_bar">
    <div class="flex flex-col lg:flex-row items-center justify-between mb-3">
      <div class="text-base font-bold lg:text-lg">
        {{ 'Add column' | translate }}
      </div>
      <div class="mt-2 lg:mt-0 flex flex-wrap lg:flex-nowrap">
        <p-button
          icon="pi pi-times"
          severity="secondary"
          [rounded]="true"
          [link]="true"
          (click)="closeFormEditor()" />
      </div>
    </div>
    <p-divider class="w-full" />

    <div class="p-2">
      <offconon-pipeline-step-stage-column-common-form
        [step_pipeline_id]="step_pipeline_id"
        [step_data]="edit_step_data"
        (editformSavedEvent)="editStepSave($event)"
        (formSavedEvent)="newStep($event)" />
    </div>
  </p-drawer>
}
