import { NgStyle } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ConfirmationService, ConfirmEventType, MenuItem, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DrawerModule } from 'primeng/drawer';
import { MenuModule } from 'primeng/menu';

import { PipelineStepStageColumnCommonFormComponent } from '@offconon/shared/ui/pipeline-step-stage-column-common-form';

import { deleteStep } from '../../../data/store/pipeline.actions';

@Component({
  selector: 'offconon-step-stage-column',
  templateUrl: './step-stage-column.component.html',
  imports: [
    NgStyle,
    ButtonModule,
    MenuModule,
    DialogModule,
    TranslateModule,
    DrawerModule,
    PipelineStepStageColumnCommonFormComponent,
    DividerModule,
  ],
})
export class StepStageColumnComponent implements OnInit {
  private translateService = inject(TranslateService);

  @Input() step_stage_data: any;
  @Input() position: number;
  @Input() step_pipeline_id: number;

  @Output() addStepEvent = new EventEmitter<any>();
  @Output() editStepEvent = new EventEmitter<any>();

  items: MenuItem[];
  descVisible = false;
  step_full_bar = false;
  space_way = 'after';
  edit_step_data: any;

  private messageService = inject(MessageService);
  private store = inject(Store);
  private confirmationService = inject(ConfirmationService);

  ngOnInit() {
    this.items = [
      {
        label: this.translateService.instant('Options'),
        visible: true,
        items: [
          {
            label:
              this.translateService.instant('Add column') +
              ' - ' +
              this.translateService.instant('after'),
            icon: 'pi pi-plus',
            visible: true,
            command: () => {
              this.addStep(this.position + 1);
            },
          },
          {
            label:
              this.translateService.instant('Add column') +
              ' - ' +
              this.translateService.instant('befor'),
            icon: 'pi pi-plus',
            visible: true,
            command: () => {
              this.addStep(this.position);
            },
          },
          {
            label: this.translateService.instant('Description'),
            icon: 'pi pi-info',
            visible: true,
            command: () => {
              this.showDescription();
            },
          },
          {
            label: this.translateService.instant('Edit'),
            icon: 'pi pi-refresh',
            visible: true,
            command: () => {
              this.update();
            },
          },
          {
            label: this.translateService.instant('Delete'),
            icon: 'pi pi-times',
            visible: true,
            command: () => {
              this.delete();
            },
          },
        ],
      },
    ];
  }

  update() {
    this.editStepEvent.emit(this.step_stage_data);
  }

  editStepSave(event: any) {
    this.closeFormEditor();
  }

  newStep(event: any) {
    this.closeFormEditor();
  }

  delete() {
    this.confirmationService.confirm({
      message:
        this.translateService.instant('Are you sure you want to delete this?') +
        ' ' +
        this.translateService.instant('This decision can not be reversed!'),
      header: this.translateService.instant('Delete'),
      icon: 'pi pi-exclamation-triangle',

      accept: () => {
        this.store.dispatch(deleteStep({ id: this.step_stage_data.id as number }));
      },

      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              severity: 'warn',
              summary: this.translateService.instant('Cancel'),
              detail: this.translateService.instant('This item has remained active!'),
            });
            break;
        }
      },
      key: 'deletePiplineStepDialog',
    });
  }

  addStep(position: number) {
    this.edit_step_data = {
      order_id: position,
    };
    this.step_full_bar = true;
  }

  showDescription() {
    this.descVisible = true;
  }

  closeFormEditor() {
    this.step_full_bar = false;
    this.edit_step_data = null;
    this.space_way = 'after';
  }
}
