import { CommonModule } from '@angular/common';
import { Component, Input, inject, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { fetchStorage, toggleSelection } from '../../../store/media-gallery.actions';
import { selectDetailedAlbumItemsWithSelected } from '../../../store/media-gallery.selectors';

@Component({
  selector: 'offconon-media-gallery-content',
  imports: [CommonModule, TranslateModule],
  templateUrl: './media-gallery-content.component.html',
  styles: ``,
})
export class MediaGalleryContentComponent implements OnInit, OnChanges {
  @Input() multiple = false;
  @Input() showType: 'image' | 'video' | 'audio' | 'document' | 'zip' | 'other' = 'image';
  private store = inject(Store);
  paginatedDetailedAlbumItemList = toSignal(
    this.store.select(selectDetailedAlbumItemsWithSelected),
  );

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showType']) {
      this.store.dispatch(
        fetchStorage({
          page: 1,
          pageSize: 99,
          searchJson: {
            file_type: this.showType,
          },
        }),
      );
    }
  }

  ngOnInit(): void {
    if (!this.paginatedDetailedAlbumItemList()) {
      this.store.dispatch(
        fetchStorage({
          page: 1,
          pageSize: 99,
          searchJson: {
            file_type: this.showType,
          },
        }),
      );
    }
  }

  toggleSelection(id: number): void {
    if (!this.multiple) {
      const currentItem = this.paginatedDetailedAlbumItemList()?.results.find(
        (item) => item.id === id,
      );
      if (currentItem?.selected) {
        this.store.dispatch(toggleSelection({ id }));
        return;
      }

      const selectedItem = this.paginatedDetailedAlbumItemList()?.results.find(
        (item) => item.selected,
      );
      if (selectedItem) {
        this.store.dispatch(toggleSelection({ id: selectedItem.id || 0 }));
      }
    }
    this.store.dispatch(toggleSelection({ id }));
  }
}
