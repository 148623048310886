<div class="columns-2 lg:columns-3 xl:columns-4 columns-break gap-4 space-y-4">
  @for (media of results; track media.id) {
    <div
      class="relative cursor-pointer transition-all duration-200 h-fit w-32"
      [class.hover:opacity-80]="!media.selected"
      [draggable]="false"
      (click)="toggleSelection(media.id || 0)">
      @switch (media.type) {
        @case ('album') {
          <div class="flex flex-col justify-center items-center p-3">
            <div class="flex justify-center items-center">
              <mat-icon class="" fontIcon="folder" />
            </div>
            <div>{{ media.detail.name || media.id }}</div>
          </div>
        }
        @case ('image') {
          <div class="w-32">
            <img alt="Selectable" class="" [src]="media.url" />
          </div>
        }
        @case ('video') {
          <div class="break-words">
            <video controls autoplay muted>
              <source type="video/mp4" [src]="media.url" />
              <source type="video/avi" [src]="media.url" />
              <source type="video/webm" [src]="media.url?.replace('.mp4', '.webm')" />
              <source type="video/ogg" [src]="media.url?.replace('.mp4', '.ogv')" />
              {{ 'Your browser does not support the video tag.' | translate }}
            </video>
            <span>{{ media.url }}</span>
          </div>
        }
        @case ('audio') {
          <div class="break-words">
            <audio alt="Selectable" controls [src]="media.url" [muted]="media.selected"></audio>
            <span>{{ media.url }}</span>
          </div>
        }
        @case ('document') {
          <div class="break-words flex flex-col justify-center items-center p-3">
            <div class="flex justify-center items-center">
              <mat-icon class="" fontIcon="insert_drive_file" />
            </div>
            <div>{{ media.url }}</div>
          </div>
        }
        @case ('other') {
          <div class="break-words flex flex-col justify-center items-center p-3">
            <div class="flex justify-center items-center">
              <mat-icon class="" fontIcon="insert_drive_file" />
            </div>
            <div>{{ media.url }}</div>
          </div>
        }
      }
      @if (media.selected) {
        <div class="bg-blue-500 rounded-full p-1 box-icon icon-absolute-center">
          <i class="pi pi-check"></i>
        </div>
      }
    </div>
  } @empty {
    <div>No media items available.</div>
  }
</div>
@if (
  (datas?.total_objects || 0) > pageSize && loading === false && (datas?.total_pages || 0) > page
) {
  <div #moreItem style="height: 50px; width: 100%">LOADMORE</div>
}
@if ((datas?.total_objects || 0) > pageSize && loading === false && datas?.total_pages === page) {
  <div class="flex items-center justify-center">
    <p class="text-xs text-500 ml-1 pt-1 text-gray">
      {{ 'No more items' | translate }}
    </p>
  </div>
}
