import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TaskEffects } from './task.effects';
import { TASK_FEATURE_KEY, taskReducer } from './task.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(TASK_FEATURE_KEY, taskReducer),
    EffectsModule.forFeature(TaskEffects),
  ],
})
export class TaskStateModule {}
