<form action="" class="grip w-full max-w-full pb-5" [formGroup]="form" (submit)="submit()">
  <div class="flex flex-col pb-2">
    <div class="flex flex-col gap-2 mb-4 mt-4">
      <p-floatlabel variant="on">
        <input formControlName="title" id="title" type="text" pInputText class="w-full" />
        <label for="title"> {{ 'Name' | translate }} </label>
      </p-floatlabel>
      <small class="block mt-1 leading-6 text-xs">
        {{ 'Enter the name of the album.' | translate }}
        {{ 'Maximum characters:' | translate }} 25
      </small>
    </div>

    <div class="flex flex-col gap-2 mb-4">
      <label for="description" class="font-medium capitalize">{{
        'Description' | translate
      }}</label>
      <textarea
        id="description"
        type="text"
        pTextarea
        rows="3"
        name="description"
        [autoResize]="false"
        [formControlName]="'description'"></textarea>
      <small class="block leading-6 mt-1">
        {{ 'Enter a description for the album (Opcional).' | translate }}
        {{ 'Maximum characters:' | translate }} 85
      </small>
    </div>

    <div class="flex flex-col gap-2 mb-4">
      <label class="font-medium">{{ 'Language' | translate }}</label>
      <offconon-langauge-dropdown-virtual-scroll-common
        [selectedId]="actual_language_id"
        [parameters]="language_parameters"
        [languageFormControl]="languageFormControl" />

      <small class="block mt-1 leading-6 text-xs">{{
        'Please let us know what language you use to improve the accuracy of our search results.'
          | translate
      }}</small>
    </div>

    <div class="flex flex-col gap-2 mb-4">
      <label for="media_share" class="font-medium">{{ 'Album visibility' | translate }}</label>
      <p-select
        optionValue="code"
        optionLabel="name"
        [formControlName]="'media_share'"
        [options]="media_share_option"
        [showClear]="false" />
      <small class="block mt-1 leading-6 text-xs">{{
        'Choose who can view your album.' | translate
      }}</small>
    </div>
  </div>

  <div class="flex flex-row-reverse flex-wrap pt-3">
    <div class="flex items-center justify-center">
      <p-button
        icon="pi pi-save"
        iconPos="left"
        type="submit"
        [styleClass]="'w-full'"
        label="{{ 'Save' | translate }}"
        [loading]="loading"
        [disabled]="form.invalid"
        [rounded]="true" />
    </div>
  </div>
</form>
