import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  OnChanges,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CropperComponent } from 'angular-cropperjs';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { MeterGroupModule } from 'primeng/metergroup';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SelectModule } from 'primeng/select';
import { forkJoin } from 'rxjs';

import { AuthService } from '@offconon/core/features/auth';
import { SystemService, DriveService } from '@offconon/core-api';
import { SiteLanguageService } from '@offconon/shared/utils/services/site-language';

import { SharedUiImageCropperComponent } from '../../../../../../ui/image-cropper/src/lib/shared-ui-image-cropper/shared-ui-image-cropper.component';
import { LoadingLoaderTriangleComponent } from '../../../../../../ui/loading-loader-cube/src/lib/options/loading-loader-triangle/loading-loader-triangle.component';
import {
  deleteMediaItemFromAlbum,
  fetchDriveFolders,
  fetchStorageDetails,
  restoreItems,
} from '../../../store/media-gallery.actions';
import {
  selectDriveFolderList,
  selectFolderList,
  selectStorageDetails,
} from '../../../store/media-gallery.selectors';
import { FolderBreadcrumbComponent } from '../folder-list/folder-breadcrumb.component';
import { FolderListComponent } from '../folder-list/folder-list.component';
import { FolderTreeComponent } from '../folder-tree/folder-tree.component';
import { MediaFolderContentComponent } from '../media-folder-content/media-folder-content.component';
import { MediaGalleryModule } from '../media-gallery.module';
import { MediaRecycleContentComponent } from '../media-recycle-content/media-recycle-content.component';

@Component({
  selector: 'offconon-media-folder-uploader',
  templateUrl: './media-folder-uploader.component.html',
  styleUrls: ['./image-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService],
  imports: [
    CommonModule,
    ButtonModule,
    TranslateModule,
    SharedUiImageCropperComponent,
    MediaGalleryModule,
    SelectModule,
    FormsModule,
    FolderListComponent,
    ConfirmDialogModule,
    ProgressSpinnerModule,
    LoadingLoaderTriangleComponent,
    MediaFolderContentComponent,
    FileUploadModule,
    MediaRecycleContentComponent,
    MeterGroupModule,
    FolderBreadcrumbComponent,
    DialogModule,
    FolderTreeComponent,
  ],
  styles: ``,
})
export class MediaFolderUploaderComponent implements OnChanges, OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('uploader') uploader: FileUpload;
  @Output() mediaGalleryOpened = new EventEmitter<boolean>();
  @Output() selectedMediaEmit = new EventEmitter<any>();
  @Input() aspectRatio = 2 / 1;
  @Input() useWithCropper = false;
  @Input() multiple = false;
  @Input() upload_area = 'media_upload';
  @Input() uploadType = '';
  @Input() album_id: number;
  @Input() original_cover_data: any = undefined;
  @Input() original_avatar_data: any = undefined;
  @Input() croppedMaxHeight = 2500;
  @Input() croppedMaxWidth = 2500;
  @Input() originalMaxHeight = 2500;
  @Input() originalMaxWidth = 2500;
  @Input() canSelect = true; // if needs select items to upload like activitystream
  @Input() onlyEdit = false;
  @Input() imageUrl: string = '';
  @Input() business_id: any = undefined;
  @Input() systemType = 'Album';
  @Input() url: string;
  @Input() selectedChildren: any[] = [];
  @Input() selectedChildrenParents: any[] = [];
  @Input() currentFolder: any[] = [];
  @Input() accept = 'image/*';
  @Input() maxFileSize = 3024000;
  @Input() post_state = 'normal';
  @Input() onlyCrop = false;
  @Output() CroppedImageUploadEvent = new EventEmitter<any>();
  @Output() ImageUploadEvent = new EventEmitter<any>();
  @ViewChild('uploadBtn') public uploadButton: ElementRef<HTMLInputElement>;
  @ViewChild('image', { static: false }) public imageCropper: CropperComponent;

  public fileToUpload: any;
  public imageDestination: any;
  public config: any;
  public newFiles: {};
  public image_url: string = '';
  public showImageCropper = false;
  public showImages = false;
  public acceptedTypes: any[] = [];
  public loading = false;
  public folderId: any;
  public showType: 'all' | 'image' | 'video' | 'audio' | 'document' | 'zip' | 'other' = 'all';
  public options: {
    name: string;
    code: 'image' | 'video' | 'audio' | 'document' | 'zip' | 'other' | 'all';
  }[] = [{ name: 'All', code: 'all' }];
  public user_id = 0;
  public selectedRecyceItems: any[];
  public optionTypes: string[];
  public originalFile: any = undefined;
  private destroyRef = inject(DestroyRef);
  private systemService = inject(SystemService);
  private authenticationService = inject(AuthService);
  private store = inject(Store);
  private driveService = inject(DriveService);
  private messageService = inject(MessageService);
  selectedMedia: any[] = [];
  private siteLanguageService = inject(SiteLanguageService);
  private translate = inject(TranslateService);
  private confirmationService = inject(ConfirmationService);
  public folderPageSize = 100;
  moveIds: number[] = [];
  selectFolder = false;
  actual_language_id = 71;
  showUploader = false;
  storageDetails = toSignal(this.store.select(selectStorageDetails));
  folderList = toSignal(this.store.select(selectFolderList));
  folderDriveList = toSignal(this.store.select(selectDriveFolderList));

  storage_values = [
    {
      label: this.translate.instant('Documents'),
      value: 10,
      color: 'rgb(52, 211, 153)',
      icon: 'pi pi-file',
    },
    {
      label: this.translate.instant('Images'),
      value: 20,
      color: 'rgb(251, 191, 36)',
      icon: 'pi pi-image',
    },
    {
      label: this.translate.instant('Videos'),
      value: 10,
      color: 'rgb(96, 165, 250)',
      icon: 'pi pi-video',
    },
    {
      label: this.translate.instant('Audio'),
      value: 10,
      color: 'rgb(192, 132, 252)',
      icon: 'pi pi-volume-up',
    },
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['uploadType'] || changes['system_data'] || changes['onlyCrop']) {
      if (
        ((this.original_cover_data && this.original_cover_data !== undefined) ||
          (this.original_avatar_data && this.original_avatar_data !== undefined)) &&
        this.onlyCrop
      ) {
        if (this.uploadType === 'cover') {
          this.album_id = this.original_cover_data?.folder_id;
          this.originalFile = {
            media_file_id: this.original_cover_data?.id,
            media_file_url: this.original_cover_data?.file_path,
            album_id: this.original_cover_data?.folder_id,
          };

          this.selectedFolderId(this.original_cover_data?.folder_id);
          this.addToEditor(this.original_cover_data?.file_path);
        }
        if (this.uploadType === 'avatar') {
          this.originalFile = {
            media_file_id: this.original_avatar_data?.id,
            media_file_url: this.original_avatar_data?.file_path,
            album_id: this.original_avatar_data?.folder_id,
          };
          this.album_id = this.original_avatar_data?.folder_id;
          this.selectedFolderId(this.original_avatar_data?.folder_id);
          this.addToEditor(this.original_avatar_data?.file_path);
        }
      }
    }
    if (changes['onlyEdit']) {
      this.onlyEdit = changes['onlyEdit'].currentValue;
    }
    if (changes['ascpectRatio']) {
      this.aspectRatio = changes['aspectRatio'].currentValue;
    }
    if (changes['onlyEdit'] && changes['onlyEdit'].currentValue === false) {
      this.reset();
    }
    if (!changes['onlyEdit'] && this.onlyEdit === false) {
      this.reset();
    }
    //this.ngOnInit();
  }

  ngOnInit(): void {
    this.store.dispatch(fetchStorageDetails());
    /*this.store.dispatch(
      fetchFolders({
        page: 1,
        pageSize: this.folderPageSize,
        searchJson: {
          user_id: this.user_id,
          business_id: this.business_id,
        },
      }),
    );*/
    this.store.dispatch(
      fetchDriveFolders({
        page: 1,
        pageSize: this.folderPageSize,
        searchJson: {
          user_id: this.user_id,
          business_id: this.business_id,
        },
      }),
    );
    this.user_id = this.authenticationService.currentUserValue?.id || 0;
    let newAccept = 'all/*,' + this.accept;
    newAccept = newAccept.replace('application/*,', '');
    newAccept = newAccept.replace('application/*', '');
    this.acceptedTypes = this.accept.split(',').map((type) => type.split('/')[0]);
    this.optionTypes = newAccept.split(',').map((type) => type.split('/')[0]);

    this.options = this.optionTypes
      .map((element) => {
        return {
          code: element.toLowerCase() as 'image' | 'video' | 'audio' | 'document' | 'zip' | 'other',
          name: element.charAt(0).toUpperCase() + element.slice(1),
        };
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    this.actual_language_id = this.siteLanguageService.actualSiteLanguageId();
    if (this.onlyEdit) {
      this.showImageCropper = true;
    }
    if (this.imageUrl) {
      this.fileToUpload = true;
    }
    this.config = {
      aspectRatio: this.aspectRatio,
      zoomable: true,
      movable: true,
      responsive: true,
      zoom: 1,
      autoCropArea: 1,
      allowMoveImage: true,
    };
  }

  selectedFolderId(event: any) {
    if (event !== this.folderId) {
      this.folderId = event;
      if (event === undefined || event === 'Recycle') {
        this.showImages = false;
        this.selectedMedia = [];
      } else {
        this.showImages = true;
      }

      this.album_id = event;

      const folderList: any[] | undefined = this.folderDriveList()?.results;

      const findFolderAndRelations = (
        folders: any[],
        folderId: string,
      ): { foundFolder: any; parents: any[]; children: any[] } => {
        let foundFolder = null;
        let parents: any[] = [];
        let children: any[] = [];

        for (let folder of folders) {
          if (folder.id === folderId) {
            foundFolder = folder;
            if (folder.children && folder.children.length > 0) {
              children = folder.children;
            }
            break;
          }

          if (folder.children && folder.children.length > 0) {
            const result = findFolderAndRelations(folder.children, folderId);
            if (result.foundFolder) {
              foundFolder = result.foundFolder;
              children = result.children;
              break;
            }
          }
        }

        return { foundFolder, parents, children };
      };

      const { foundFolder, parents, children } = findFolderAndRelations(folderList || [], event);

      if (foundFolder) {
        let parentFolder = foundFolder;
        let parentChain: any[] = [];

        while (parentFolder && parentFolder.parent_id) {
          const { foundFolder: parentFound } = findFolderAndRelations(
            folderList || [],
            parentFolder.parent_id,
          );
          if (parentFound) {
            parentChain.push(parentFound);
            parentFolder = parentFound;
          } else {
            break;
          }
        }

        const childFolders = foundFolder.children;

        this.selectedChildren = [...childFolders];
        this.selectedChildrenParents = [...parentChain];
        this.currentFolder = foundFolder;
      }
    }
  }

  showMediaType(event: any) {
    const selectedValue = event.value;
    this.showType = selectedValue;
  }

  setSelectedItem(event: any) {
    this.selectedMedia = [];
    this.selectedMedia.push(event);
  }
  openFileInput() {
    this.fileInput.nativeElement.click();
  }
  onUpload(event: any) {
    this.onFileSelected(event);
  }

  onFileSelected(event: any) {
    if (!this.folderId) {
      this.messageService.add({
        severity: 'Error',
        summary: this.translate.instant('Error'),
        detail: this.translate.instant('Please select a folder to upload media.'),
      });
      this.reset();
      this.ngOnInit();
      return;
    }
    let fileList: File[] = event.files;
    if (!event.files) {
      fileList = event;
    }
    if (fileList && fileList.length > 0) {
      const files: File[] = Array.from(fileList);
      if (files) {
        this.uploadFiles(files);
      }
    }
  }
  uploadFiles(files: File[]) {
    this.loading = true;
    let fId = this.selectedMedia[0] ? this.selectedMedia[0].album_id : this.album_id;
    const uploads: any = [];
    if (!this.business_id || this.business_id === 0) {
      this.business_id = undefined;
    }
    if (files.length > 0) {
      files.forEach((file) => {
        let fileType = '';
        switch (true) {
          case file.type.includes('image'):
            fileType = 'image';
            break;

          case file.type.includes('audio'):
            fileType = 'audio';
            break;

          case file.type.includes('video'):
            fileType = 'video';
            break;
          case file.type.includes('application'):
            fileType = 'document';
            break;
          case file.type.includes('application/zip'):
            fileType = 'zip';
            break;

          default:
            fileType = 'other';
            break;
        }

        const temp = this.driveService.driveUploadCreate(
          file,
          fId,
          this.business_id,
          fileType,
          undefined,
        );
        uploads.push(temp);
      });
      forkJoin(uploads).subscribe({
        next: (res: any) => {
          //const fileResults: UploadRespons[] = [];
          res.forEach((element: any) => {
            //fileResults.push(element.body);
            const temp = {
              album_id: this.album_id ?? fId,
              business_id: this.business_id,
              id: element.album_item_id,
              is_reported: 'un_report',
              media_detail: null,
              media_file_id: element.media_file_id,
              media_file_type: element.content_upload_type,
              media_file_url: element.file_path,
              thumbnail_file_url: element.thumbnail_file_path,
              selected: false,
              file_size: element.file_size,
              user_id: this.user_id,
            };

            this.newFiles = temp;
            let original = this.selectedMedia[0] ? this.selectedMedia[0] : this.originalFile;
            if (original) {
              let croppedDataObj = {
                type: this.uploadType,
                original: original,
                cropped: temp,
              };
              this.CroppedImageUploadEvent.emit(croppedDataObj);
            }
          });
          const errors = res.errors;
          if (!errors || errors.length === 0) {
            this.loading = false;
            this.showUploader = false;
            this.selectedFolderId(fId);
            this.messageService.add({
              severity: 'success',
              summary: this.translate.instant('Successful'),
              detail: this.translate.instant('Your data has been successfully saved.'),
            });
          } else {
            this.loading = false;
            // eslint-disable-next-line no-console
            console.error(errors);
          }
        },
      });
    }
  }
  closeMediaGallery() {
    this.mediaGalleryOpened.emit(false);
  }
  editImage(event: any) {
    this.selectedMedia = [];
    this.selectedMedia.push(event);
    this.addToEditor(event.media_file_url);
  }
  addToEditor(image?: any) {
    let selected = this.selectedMedia;

    if ((selected && selected.length === 1) || image) {
      let img = image ? image : selected[0].media_file_url;

      if (img) {
        let image_url = img;
        this.systemService
          .systemConvertImageUrlToBase64Create({ image_url })
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: (data) => {
              this.imageUrl = 'data:image/png;base64, ' + data.base64;
              this.showImageCropper = true;
            },
          });
      }
    }
    //this.store.dispatch(resetSelectedMediaIds());
  }
  moveSelected() {
    this.moveIds = this.selectedMedia[0]?.map((media: any) => media.id).filter((id: any) => id);
    this.selectFolder = true;
  }
  closeDialog() {
    this.selectFolder = false;
  }
  recycleSelected(event: any) {
    this.selectedRecyceItems = event;
  }
  restoreRecycled() {
    if (this.selectedRecyceItems?.length) {
      let album_ids = [],
        album_item_ids = [];
      for (const element of this.selectedRecyceItems) {
        if (element.type === 'album') {
          album_ids.push(element.id);
        } else {
          album_item_ids.push(element.id);
        }
      }
      this.store.dispatch(
        restoreItems({ albumIdItems: album_ids, albumItemIdItems: album_item_ids }),
      );
      /*if (album_ids && album_ids.length > 0) {

        album_ids.forEach((id: any) => {
          this.driveService
            .driveRestoreUpdate(id, undefined)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
              next: (data) => {},
              error: (err) => {},
            });
        });
      }
      if (album_item_ids && album_item_ids.length > 0) {
        album_item_ids.forEach((id: any) => {
          this.driveService
            .driveRestoreUpdate(undefined, id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
              next: (data) => {},
              error: (err) => {},
            });
        });
      }*/

      this.messageService.add({
        severity: 'success',
        summary: this.translate.instant('Success'),
        detail: this.translate.instant('Items restored successfully!'),
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: this.translate.instant('Error'),
        detail: this.translate.instant('Please select items before restore!'),
      });
    }
  }
  deleteRecycled() {
    if (this.selectedRecyceItems?.length) {
      let album_ids: number[] = [],
        album_item_ids: number[] = [];
      for (const element of this.selectedRecyceItems) {
        if (element.type === 'album') {
          album_ids.push(element.id);
        } else {
          album_item_ids.push(element.id);
        }
      }
      this.confirmationService.confirm({
        message: this.translate.instant('Are you sure you want to delete this?'),
        header: this.translate.instant('Attention'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          /*
          this.driveService
            .driveRestoreDestroy(album_ids, album_item_ids)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
              next: (data) => {},
            });
          this.messageService.add({
            severity: 'info',
            summary: this.translate.instant('Successful'),
            detail: this.translate.instant('Your request has been successfully completed.'),
          });*/
        },
        key: 'deleteRestoreDialog',
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: this.translate.instant('Error'),
        detail: this.translate.instant('Please select items before delete'),
      });
    }
  }

  addToMedia() {
    this.selectedMedia[0]?.forEach((media: any) => {
      if (this.acceptedTypes.some((type) => media.media_file_type?.includes(type))) {
        this.selectedMediaEmit.emit(media);
      } else {
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('Error'),
          detail: this.translate.instant('The selected file type is not accepted.'),
        });
      }
    });
  }

  deleteMedia() {
    let media_ids = this.selectedMedia[0]?.map((media: any) => media.id).filter((id: any) => id);
    if (media_ids && media_ids.length > 0) {
      this.confirmationService.confirm({
        message: this.translate.instant('Are you sure you want to delete this?'),
        header: this.translate.instant('Attention'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.store.dispatch(deleteMediaItemFromAlbum({ id: media_ids }));
          this.messageService.add({
            severity: 'info',
            summary: this.translate.instant('Successful'),
            detail: this.translate.instant('Your request has been successfully completed.'),
          });
        },
        key: 'deleteMediaDialog',
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: this.translate.instant('Error'),
        detail: this.translate.instant('No selected item found!'),
      });
    }
  }
  public onUploadImage(e: any): void {
    if (e.target.files && e.target.files?.length) {
      this.fileToUpload = e.target.files[0];

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageUrl = e.target.result;
      };
      reader.readAsDataURL(this.fileToUpload);
    }
  }

  resizeImage(img: any, maxWidth = 2500, maxHeight = 2500) {
    var width = img.width;
    var height = img.height;

    if (width > height) {
      if (width > maxWidth) {
        height = height * (maxWidth / width);
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width = width * (maxHeight / height);
        height = maxHeight;
      }
    }

    var canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    var ctx: any = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, width, height);
    var dataurl = canvas.toDataURL(img.type);
    return dataurl;
  }

  public exportCroppedImage() {
    this.imageDestination = this.imageCropper.cropper.getCroppedCanvas().toDataURL('image/png');
    this.showImageCropper = false;
    this.imageUrl = '';
  }

  public cropImage(): void {
    this.imageCropper.exportCanvas();
    const croppedImgUrl = this.resizeImage(
      this.imageCropper.cropper.getCroppedCanvas(),
      this.croppedMaxWidth,
      this.croppedMaxHeight,
    );

    const tempCanvas = this.imageCropper.cropper;
    const url = this.resizeImage(
      tempCanvas.clear().getCroppedCanvas(),
      this.originalMaxWidth,
      this.originalMaxHeight,
    );
    /*this.onFileSelected(
      this.dataURLtoFile(croppedImgUrl, this.selectedMedia[0].id + '-cropped.png'),
    );*/
    const croppedFile = this.dataURLtoFile(croppedImgUrl, 'edit-cropped.png');

    let fileArray = [];
    fileArray.push(croppedFile);
    this.onFileSelected(fileArray);
    /*this.ImageUploadEvent.emit({
      cropped: this.dataURLtoFile(croppedImgUrl, 'cropped.png'),
      original: this.dataURLtoFile(url, 'original.png'),
    });*/
  }
  dataURLtoFile(dataurl: any, filename: string) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  convertToByteArray(base64: string) {
    base64 = base64.replace(/^data:image\/(png|jpg);base64,/, '');
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  public moveUpImage(): void {
    this.imageCropper.cropper.move(0, -10);
  }

  public moveDownImage(): void {
    this.imageCropper.cropper.move(0, 10);
  }

  public moveLeftImage(): void {
    this.imageCropper.cropper.move(-10, 0);
  }

  public moveRightImage(): void {
    this.imageCropper.cropper.move(10, 0);
  }

  public rotateLeftImage(): void {
    this.imageCropper.cropper.rotate(-90);
  }

  public rotateRightImage(): void {
    this.imageCropper.cropper.rotate(90);
  }

  public resetCropper(): void {
    this.imageCropper.cropper.reset();
  }

  public clickThroughUpload(): void {
    this.uploadButton.nativeElement.click();
  }

  public reset(): void {
    this.imageDestination = null;
    this.fileToUpload = null;
    this.showImageCropper = false;
    this.imageUrl = '';
  }

  ngOnDestroy(): void {
    if (this.imageCropper && this.imageCropper.cropper) this.imageCropper.cropper.reset();
  }
}
