import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { MediaGalleryEffects } from '../../store/media-gallery.effects';
import { MEDIA_GALLERY_FEATURE_KEY, mediaGalleryReducer } from '../../store/media-gallery.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(MEDIA_GALLERY_FEATURE_KEY, mediaGalleryReducer),
    EffectsModule.forFeature(MediaGalleryEffects),
  ],
})
export class MediaGalleryModule {}
