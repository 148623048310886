import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  inject,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
  DestroyRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { PaginatorModule } from 'primeng/paginator';

import { getRestorableItems } from '../../../store/media-gallery.actions';
import { selectRestorableItems, selectRestoredItems } from '../../../store/media-gallery.selectors';

@Component({
  selector: 'offconon-media-recycle-content',
  templateUrl: './media-recycle-content.component.html',
  imports: [CommonModule, TranslateModule, PaginatorModule, DragDropModule, MatIcon],
  styles: ``,
})
export class MediaRecycleContentComponent implements OnDestroy, OnInit {
  @Input() user_id: number = 0;
  @Input() business_id: any = null;
  @Input() language_id: any = 71;
  @Input() showType: 'image' | 'video' | 'audio' | 'document' | 'zip' | 'other' = 'image';
  @Output() selectedItemEmit = new EventEmitter<any>();

  @ViewChild('moreItem', { static: false })
  private loadMoreDiv: ElementRef<HTMLDivElement>;
  isLoadMoreDivScrolledIntoView: boolean;

  private store = inject(Store);
  private destroyRef = inject(DestroyRef);
  pageSize = 10;
  page: number = 1;
  loading = false;
  public datas: any;
  public results: any;
  public selectedIds: number[] = [];
  public selectedItem: any[] = [];

  ngOnDestroy(): void {
    document.removeEventListener('scroll', this.scroll, true);
  }

  ngOnInit(): void {
    this.datas = [];
    this.results = [];

    this.store.dispatch(
      getRestorableItems({
        page: this.page,
        pageSize: this.pageSize,
        searchJson: {
          language_id: this.language_id,
          business_id: this.business_id,
        },
      }),
    );
    this.store
      .select(selectRestorableItems)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: any) => {
        if (data) {
          this.datas = data;
          this.results = [
            ...this.results,
            ...data.results.filter(
              (newItem: any) =>
                !this.results.some((existingItem: any) => existingItem.id === newItem.id),
            ),
          ];
        }
      });
    this.store
      .select(selectRestoredItems)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((restoredItems) => {
        restoredItems?.albumIdItems.forEach((element) => {
          if (element) {
            if (element) {
              this.results = this.results.filter(
                (item: any) => !(item.type === 'album' && item.id === element),
              );
            }
          }
        });
        restoredItems?.albumItemIdItems.forEach((itemElement) => {
          if (itemElement) {
            if (itemElement) {
              this.results = this.results.filter(
                (item: any) => !(item.type !== 'album' && item.id === itemElement),
              );
            }
          }
        });
      });
    document.addEventListener('scroll', this.scroll, true);
  }

  scroll = (e: any) => {
    this.checkIfInView();
  };

  checkIfInView() {
    if (!this.loading) {
      const rect = this.loadMoreDiv?.nativeElement.getBoundingClientRect();
      const isInView = rect?.top >= 0 && rect.bottom <= window.innerHeight;

      this.isLoadMoreDivScrolledIntoView = isInView;
      if (isInView && !this.loading) {
        this.loadMore();
      }
    }
  }
  loadMore() {
    this.loading = true;
    if (this.page > (this.datas?.total_pages || 0)) {
      return;
    }
    this.page++;

    this.store.dispatch(
      getRestorableItems({
        page: this.page,
        pageSize: this.pageSize,
        searchJson: {
          language_id: this.language_id,
          business_id: this.business_id,
        },
      }),
    );
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  toggleSelection(id: number): void {
    const index = this.results.findIndex((item: any) => item.id === id);
    if (index !== -1) {
      const isSelected = this.results[index].selected;

      this.results = this.results.map((item: any) => {
        if (item.id === id) {
          return {
            ...item,
            selected: !isSelected,
          };
        }
        return item;
      });

      if (isSelected) {
        this.selectedIds = this.selectedIds.filter((itemId) => itemId !== id);
      } else {
        this.selectedIds.push(id);
      }
    }

    this.results = this.results.map((item: any) => {
      if (!this.selectedIds.includes(item.id)) {
        return {
          ...item,
          selected: false,
        };
      }
      return item;
    });

    const selectedItems = this.results.filter((item: any) => item.selected);
    this.selectedItem = selectedItems;

    this.selectedItemEmit.emit(selectedItems);
  }
}
