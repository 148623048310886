<div
  class="relative cursor-pointer transition-all duration-200 h-fit"
  [class.hover:opacity-80]="!media.selected">
  <div data-testid="folder_content__menu">
    <span
      class="w-6 h-6 text-center absolute top-1 right-1 cursor-pointer rounded-full box-bg hover:bg-primary"
      (click)="menu.toggle($event); $event.stopPropagation()">
      <i class="pi pi-ellipsis-v" style="font-size: 12px"></i>
    </span>
    <p-menu
      #menu
      data-testid="folder_content__menu-popup"
      [appendTo]="'body'"
      [popup]="true"
      [model]="items">
      <ng-template #start />
      <ng-template #submenuheader let-item>
        @if (item?.visible !== false) {
          <div class="text-blue-500 font-bold" data-testid="folder_content__submenu-header">
            {{ item.label }}
          </div>
        }
      </ng-template>
      <ng-template #item let-item>
        @if (item?.visible !== false) {
          <a
            class="flex items-center p-2 cursor-pointer gap-2"
            data-testid="folder_content__menu-item">
            <span
              class="inline-flex items-center justify-center {{
                item?.icon_color ? item?.icon_color : 'text-blue-500'
              }}">
              <mat-icon fontIcon="{{ item.icon ? item.icon : 'add_circle' }}" />
            </span>
            <span class="inline-flex flex-col gap-2">
              <span class="font-light text-sm">{{ item.label }}</span>
            </span>
          </a>
        }
      </ng-template>
    </p-menu>
  </div>

  @switch (media.media_file_type) {
    @case ('image') {
      <div>
        <!-- <span
          class="w-6 h-6 text-center absolute top-1 right-1 cursor-pointer rounded-full box-bg hover:bg-primary"
          (click)="editImage(media)">
          <i class="pi pi-pencil" style="font-size: 12px"></i>
        </span> -->
        <img alt="Selectable" class="" [src]="media.media_file_url" />
        <span class="text-xs text-right text-gray">
          ({{ 'Size' }}: {{ (media.file_size || 0) / 1000000 | number: '1.2-2' }} Mb)
        </span>
      </div>
    }
    @case ('video') {
      <div class="break-all">
        <video controls autoplay muted>
          <source type="video/mp4" [src]="media.media_file_url" />
          <source type="video/avi" [src]="media.media_file_url" />
          <source type="video/webm" [src]="media.media_file_url?.replace('.mp4', '.webm')" />
          <source type="video/ogg" [src]="media.media_file_url?.replace('.mp4', '.ogv')" />
          {{ 'Your browser does not support the video tag.' | translate }}
        </video>
        <div class="text-xs text-gray my-3">{{ media.media_file_url }}</div>
        <div>
          <span class="text-xs float-right text-gray">
            ({{ 'Size' }}: {{ (media.file_size || 0) / 1000000 | number: '1.2-2' }} Mb)
          </span>
        </div>
      </div>
    }
    @case ('audio') {
      <div class="break-all">
        <audio
          alt="Selectable"
          controls
          [src]="media.media_file_url"
          [muted]="media.selected"></audio>
        <span class="text-xs text-gray my-3">{{ media.media_file_url }}</span>
        <div>
          <span class="text-xs float-right text-gray">
            ({{ 'Size' }}: {{ (media.file_size || 0) / 1000000 | number: '1.2-2' }} Mb)
          </span>
        </div>
      </div>
    }
    @case ('document') {
      <div class="break-words">
        <span>{{ media.media_file_url }}</span>
        <div>
          <span class="text-xs float-right">
            ({{ 'Size' }}: {{ (media.file_size || 0) / 1000000 | number: '1.2-2' }} Mb)
          </span>
        </div>
      </div>
    }
  }
  @if (media.selected) {
    <div class="bg-blue-500 rounded-full p-1 box-icon icon-absolute-center">
      <i class="pi pi-check"></i>
    </div>
  }
</div>
<p-dialog
  header="{{ 'Select a folder' | translate }}"
  [modal]="true"
  [closeOnEscape]="true"
  [resizable]="true"
  [closable]="true"
  [dismissableMask]="true"
  [maximizable]="true"
  [style]="{ width: '50rem' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [(visible)]="selectFolder"
  (onHide)="closeDialog()">
  <div class="p-2">
    <div class="pt-3">
      @if (selectFolder) {
        <offconon-folder-tree
          [folderList]="folderList"
          [selected_file_id]="media.id"
          [selected_file_album_id]="media.album_id" />
      }
    </div>
  </div>
</p-dialog>
<p-confirmDialog
  [key]="'deleteMediaDialogCard-' + media.id"
  [breakpoints]="{ '960px': '75vw' }"
  [baseZIndex]="12"
  [appendTo]="'body'">
  <ng-template #headless let-message let-onAccept="onAccept" let-onReject="onReject">
    <div class="flex flex-col items-center p-8 bg-surface-0 dark:bg-surface-900 rounded">
      <span class="font-bold text-2xl block mb-2 mt-6">{{ message?.header }}</span>
      <p class="mb-0">{{ message?.message }}</p>
      <div class="flex items-center gap-2 mt-6">
        <p-button label="{{ 'Yes, I want to delete it.' | translate }}" (onClick)="onAccept()" />
        <p-button label="{{ 'Cancel' | translate }}" [outlined]="true" (onClick)="onReject()" />
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
