<div class="mt-3">
  @if (pipeline?.steps.length < 1) {
    <div class="pt-3 pl-2 pr-2">
      <p-button
        icon="pi pi-plus"
        iconPos="left"
        class=""
        [attr.data-testid]="'add-column-button'"
        label="{{ 'Add column' | translate }}"
        (click)="openDrawer = true" />
    </div>
  }

  <p-confirmDialog
    key="deletePiplineStepDialog"
    [breakpoints]="{ '960px': '75vw' }"
    [baseZIndex]="2"
    [appendTo]="'body'">
    <ng-template
      let-message="message"
      let-onAccept="onAccept"
      let-onReject="onReject"
      pTemplate="headless">
      <div class="flex flex-col items-center p-8 bg-surface-0 dark:bg-surface-900 rounded">
        <span class="font-bold text-2xl block mb-2 mt-6">{{ message?.header }}</span>
        <p class="mb-0">{{ message?.message }}</p>
        <div class="flex items-center gap-2 mt-6">
          <p-button
            severity="danger"
            label="{{ 'Yes, I want to delete it.' | translate }}"
            (onClick)="onAccept()" />
          <p-button label="{{ 'Cancel' | translate }}" [outlined]="true" (onClick)="onReject()" />
        </div>
      </div>
    </ng-template>
  </p-confirmDialog>
  <div
    class="kanban-board min-h-[30rem]"
    cdkDropList
    cdkDropListOrientation="horizontal"
    [attr.data-testid]="'kanban-board'"
    [cdkDropListData]="pipeline?.steps"
    (cdkDropListDropped)="dropColumn($event)">
    @for (column of pipeline?.steps || []; track column.id) {
      <div class="column" cdkDrag [attr.data-testid]="'column-' + column.id">
        <div class="flex items-center justify-center" cdkDragHandle>
          <offconon-step-stage-column
            [step_stage_data]="column"
            [position]="$index"
            [step_pipeline_id]="pipeline.id"
            (editStepEvent)="editStep($event)" />
        </div>
        <div
          class="column-content"
          cdkDropList
          [attr.data-testid]="'column-content-' + column.id"
          [cdkDropListData]="column.cards"
          [id]="column.name"
          [cdkDropListConnectedTo]="getConnectedList()"
          (cdkDropListDropped)="dropCard($event, column.id)">
          <ng-template
            [ngTemplateOutlet]="createButton"
            [ngTemplateOutletContext]="{ item: column }" />

          <div class="flex flex-col mt-2 border-300 border-left-1">
            @for (card of column.cards; track card.id) {
              <div class="card" cdkDrag [attr.data-testid]="'card-' + card.id">
                <ng-template
                  [ngTemplateOutlet]="cardTemplate"
                  [ngTemplateOutletContext]="{ item: card }" />
              </div>
            } @empty {
              <p class="empty-message text-center text-sm">
                {{ 'No cards in this column' | translate }}
              </p>
            }
          </div>
        </div>
      </div>
    }
  </div>

  @defer (when openDrawer) {
    <p-drawer
      [fullScreen]="false"
      [baseZIndex]="10000"
      [modal]="true"
      [appendTo]="'body'"
      [styleClass]="'surface-section'"
      [showCloseIcon]="false"
      [(visible)]="openDrawer">
      <div class="flex flex-col lg:flex-row items-center justify-between mb-3">
        <div class="text-base font-bold lg:text-lg">
          {{ 'Edit column' | translate }}
        </div>
        <div class="flex flex-wrap lg:flex-nowrap">
          <p-button
            icon="pi pi-times"
            severity="secondary"
            [rounded]="true"
            [link]="true"
            (click)="openDrawer = false" />
        </div>
      </div>
      <p-divider class="w-full" />

      <div class="p-2">
        <offconon-pipeline-step-stage-column-common-form
          [step_data]="stepData"
          [step_pipeline_id]="pipeline?.id"
          (formSavedEvent)="openDrawer = false" />
      </div>
    </p-drawer>
  }
</div>
