import { Injectable, inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { catchError, EMPTY, map, mergeMap, of, switchMap, tap } from 'rxjs';

import { SharedService } from '@offconon/core-api';

import * as PipelineActions from './pipeline.actions';

@Injectable()
export class PipelineEffects {
  private actions$ = inject(Actions);
  private sharedService = inject(SharedService);
  private messageService = inject(MessageService);
  private translateService = inject(TranslateService);
  private store = inject(Store);
  editStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PipelineActions.editStep),
      tap(() => {}),
      mergeMap((action) =>
        this.sharedService.sharedPiplineStepSettingsCreate(action.postPipelineStepRequest).pipe(
          map((pipelineStep) => PipelineActions.editStepSuccess({ pipelineStep })),
          tap(() => {
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('Successful'),
              detail: this.translateService.instant('Your data has been successfully saved.'),
            });
          }),
        ),
      ),
    ),
  );

  deleteStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PipelineActions.deleteStep),
      mergeMap((action) =>
        this.sharedService.sharedPiplineStepSettingsDestroy(action.id).pipe(
          map(() => PipelineActions.deleteStepSuccess({ id: action.id })),
          tap(() => {
            this.messageService.add({
              severity: 'info',
              summary: this.translateService.instant('Delete'),
              detail: this.translateService.instant('The item has been deleted!'),
            });
          }),
          catchError((error) => {
            if (error?.error?.errors?.[0]?.error === 'LastStage') {
              this.messageService.add({
                severity: 'error',
                summary: this.translateService.instant('You can not delete it!'),
                detail: this.translateService.instant('At least one column is required!'),
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: this.translateService.instant('Access denied'),
                detail: this.translateService.instant(
                  'We cannot delete this because it has an active connection with other modules.',
                ),
              });
            }
            return EMPTY;
          }),
        ),
      ),
    ),
  );
  fetchSteps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PipelineActions.fetchPipeline),
      switchMap((action) => {
        return this.sharedService
          .sharedStepPipelineList(action.page, action.pageSize, action.searchJson)
          .pipe(
            switchMap((stepResponse) => {
              return of(
                PipelineActions.fetchPipelineSuccess({ pipeline: stepResponse.results?.[0] }),

                PipelineActions.setLoaded({ loaded: true }),
              );
            }),
            catchError((error) => of(PipelineActions.loadPipelineFailure({ error }))),
          );
      }),
    ),
  );
  // fetchSteps$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(PipelineActions.fetchSteps),
  //     switchMap((action) => {
  //       return this.sharedService
  //         .sharedStepPipelineList(action.page, action.pageSize, action.searchJson)
  //         .pipe(
  //           switchMap((stepResponse) => {
  //             const fetchTasksActions =
  //               stepResponse.results?.[0]?.steps
  //                 ?.filter((step): step is { id: number } => typeof step.id === 'number')
  //                 .map((step) => fetchTasksForStep({ stepId: step.id })) || [];

  //             return of(
  //               TaskActions.fetchStepsSuccess({ pipelineList: stepResponse.results?.[0] }),
  //               ...fetchTasksActions,
  //               TaskActions.setLoaded({ loaded: true }),
  //             );
  //           }),
  //           catchError((error) => of(TaskActions.loadTaskFailure({ error }))),
  //         );
  //     }),
  //   ),
  // );
  addStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PipelineActions.addStep),
      mergeMap((action) =>
        this.sharedService.sharedPiplineStepSettingsCreate(action.postPipelineStepRequest).pipe(
          map((pipelineStep) => PipelineActions.addStepSuccess({ pipelineStep })),
          tap(() => {
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('Successful'),
              detail: this.translateService.instant('Your data has been successfully saved.'),
            });
          }),
        ),
      ),
    ),
  );
}
