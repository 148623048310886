import { createAction, props } from '@ngrx/store';

import {
  DetailedAlbumItem,
  PaginatedAlbumTemplateRecursiveList,
  PaginatedDetailedAlbumItemList,
  PaginatedDriveAlbumItemList,
  PaginatedDriveAlbumTemplateRecursiveList,
  PaginatedResponseDriveRestoreList,
  ResponseCloudStorageLimit,
} from '@offconon/core-api';

export const initMediaGallery = createAction('[MediaGallery Page] Init');

export const toggleSelection = createAction(
  '[MediaGallery/API] Toggle Selection',
  props<{ id: number }>(),
);
export const fetchStorage = createAction(
  '[MediaGallery/API] Fetch Storage',
  props<{
    page?: number;
    pageSize?: number;
    searchJson?: {
      file_type?: 'image' | 'video' | 'audio' | 'document' | 'zip' | 'other';
      file_name?: string;
      post_type?: 'adult' | 'normal' | 'sensitive';
      album_id?: number;
    };
  }>(),
);
export const fetchAlbumItems = createAction(
  '[MediaGallery/API] Fetch Storage Album Items',
  props<{
    page?: number;
    pageSize?: number;
    searchJson?: {
      media_file__content_upload_type?: 'image' | 'video' | 'audio' | 'document' | 'zip' | 'other';
      file_name?: string;
      post_type?: 'adult' | 'normal' | 'sensitive';
      album_id?: number;
    };
  }>(),
);
export const fetchStorageDetails = createAction('[MediaGallery/API] Fetch Storage Details');
export const fetchFolders = createAction(
  '[MediaGallery/API] Fetch Folders',
  props<{
    page?: number;
    pageSize?: number;
    searchJson?: {
      user_id: number;
      business_id?: number;
      file_type?: 'image' | 'video' | 'audio' | 'document' | 'zip' | 'other';
      file_name?: string;
      post_type?: 'adult' | 'normal' | 'sensitive';
    };
  }>(),
);
export const fetchDriveFolders = createAction(
  '[MediaGallery/API] Fetch Drive Folders',
  props<{
    page?: number;
    pageSize?: number;
    searchJson?: {
      user_id: number;
      business_id?: number;
      file_type?: 'image' | 'video' | 'audio' | 'document' | 'zip' | 'other';
      file_name?: string;
      post_type?: 'adult' | 'normal' | 'sensitive';
    };
  }>(),
);
export const fetchDriveFoldersSuccess = createAction(
  '[MediaGallery/API] Fetch Drive Folders Success',
  props<{ driveGallery: PaginatedDriveAlbumTemplateRecursiveList }>(),
);

export const resetSelectedMediaIds = createAction('[MediaGallery/API] Reset Selected Media Ids');

export const fetchStorageSuccess = createAction(
  '[MediaGallery/API] Load Media Gallery Success',
  props<{ mediaGallery: PaginatedDetailedAlbumItemList }>(),
);
export const fetchAlbumItemsSuccess = createAction(
  '[MediaGallery/API] Load Album Items Gallery Success',
  props<{ mediaGallery: PaginatedDriveAlbumItemList }>(),
);
export const fetchStorageDetailsSuccess = createAction(
  '[MediaGallery/API] Load Storage Details Success',
  props<{ storageDetails: ResponseCloudStorageLimit }>(),
);
export const fetchFolderSuccess = createAction(
  '[MediaGallery/API] Load Media Gallery Folder Success',
  props<{ folders: PaginatedAlbumTemplateRecursiveList }>(),
);

export const addMediaToStorage = createAction(
  '[MediaGallery/API] Add Media To Storage',
  props<{ files: File[] }>(),
);
export const addMediaToStorageSuccess = createAction(
  '[MediaGallery/API] Add Media To Storage Success',
  props<{ media: DetailedAlbumItem[] }>(),
);

export const addMediaToStorageFailure = createAction(
  '[MediaGallery/API] Add Media To Storage Failure',
  props<{ error: any }>(),
);

export const deleteSelectedMediaFromStorage = createAction(
  '[MediaGallery/API] Delete Media From Storage',
);
export const deleteMediaItemFromAlbum = createAction(
  '[MediaGallery/API] Delete Media Items From Storage',
  props<{ id: number[] }>(),
);
export const getRestorableItems = createAction(
  '[MediaGallery/API] Get Restorable Item From Trash',
  props<{
    page?: number;
    pageSize?: number;
    searchJson?: {
      language_id: number;
      business_id: number;
    };
  }>(),
);
export const restoreItems = createAction(
  '[MediaGallery/API] Restore Item From Trash',
  props<{ albumIdItems?: number[]; albumItemIdItems?: number[] }>(),
);
export const restoreItemsSuccess = createAction(
  '[MediaGallery/API] Restore Item From Trash Success',
  props<{ albumIdItems?: number[]; albumItemIdItems?: number[] }>(),
);
export const restoreItemsFailure = createAction(
  '[MediaGallery/API] Restore Items Failure',
  props<{ error: any }>(),
);
export const getRestorableItemsSuccess = createAction(
  '[MediaGallery/API] Get Restorable Item From Trash Success',
  props<{ restorableItems: PaginatedResponseDriveRestoreList }>(),
);
export const moveMediaItemFromAlbum = createAction(
  '[MediaGallery/API] Move Media Item From Album To Album',
  props<{
    album_item_id_items: number[];
    album_id_items: number[];
    parent_id: number;
    user_id: number;
    business_id: number;
  }>(),
);
export const deleteMediaItemFromAlbumSuccess = createAction(
  '[MediaGallery/API] Delete Media Item From Storage Success',
  props<{ id: number }>(),
);
export const deleteMediaItemFromAlbumSuccessArray = createAction(
  '[MediaGallery/API] Delete Media Item From Storage Success',
  props<{ id: number[] }>(),
);
export const deleteMediaFromStorageSuccess = createAction(
  '[MediaGallery/API] Delete Media From Storage Success',
  props<{ id: number }>(),
);
export const deleteSelectedMediaFromAlbum = createAction(
  '[MediaGallery/API] Delete Media From Album',
  props<{ id: number }>(),
);
export const deleteMediaFromAlbumSuccess = createAction(
  '[MediaGallery/API] Delete Media From Album Success',
  props<{ id: number }>(),
);
export const deleteSelectedFolderFromStorage = createAction(
  '[MediaGallery/API] Delete Folder From Storage',
  props<{ id: number }>(),
);
export const deleteFolderFromStorageSuccess = createAction(
  '[MediaGallery/API] Delete Folder From Storage Success',
  props<{ id: number }>(),
);
export const moveFolderFromStorageSuccess = createAction(
  '[MediaGallery/API] Move Folder From Storage Success',
  props<{ id: number; parent_id: number }>(),
);
