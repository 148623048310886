import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CropperComponent } from 'angular-cropperjs';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';

import { SharedService, SystemService } from '@offconon/core-api';
import { ImageUploadPayload } from '@offconon/shared/ui/image-upload';

import { SharedUiImageCropperComponent } from '../../../../../../ui/image-cropper/src/lib/shared-ui-image-cropper/shared-ui-image-cropper.component';
import {
  addMediaToStorageSuccess,
  deleteSelectedMediaFromStorage,
  resetSelectedMediaIds,
} from '../../../store/media-gallery.actions';
import { selectSelectedMediaItems } from '../../../store/media-gallery.selectors';
import { MediaGalleryModule } from '../media-gallery.module';
import { MediaGalleryContentComponent } from '../media-gallery-content/media-gallery-content.component';

@Component({
  selector: 'offconon-media-gallery-cropper',
  templateUrl: './media-gallery-cropper.component.html',
  styleUrls: ['./image-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    ButtonModule,
    MediaGalleryContentComponent,
    TranslateModule,
    SharedUiImageCropperComponent,
    MediaGalleryModule,
  ],
  styles: ``,
})
export class MediaGalleryCropperComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('fileInput') fileInput: ElementRef;
  @Output() mediaGalleryOpened = new EventEmitter<boolean>();
  @Output() selectedMediaEmit = new EventEmitter<any>();
  @Input() aspectRatio = 2 / 1;
  @Input() uploadType = '';
  @Input() croppedMaxHeight = 2500;
  @Input() croppedMaxWidth = 2500;
  @Input() originalMaxHeight = 2500;
  @Input() originalMaxWidth = 2500;
  @Input() onlyEdit = false;
  @Input() accept = 'image/*';
  @Input() imageUrl: string = '';
  @Output() ImageUploadEvent = new EventEmitter<ImageUploadPayload>();
  @ViewChild('uploadBtn') public uploadButton: ElementRef<HTMLInputElement>;
  @ViewChild('image', { static: false }) public imageCropper: CropperComponent;

  public fileToUpload: any;
  public imageDestination: any;
  public config: any;
  public image_url: string = '';
  public showImageCropper = false;

  private destroyRef = inject(DestroyRef);
  private messageService = inject(MessageService);
  private translate = inject(TranslateService);
  private systemService = inject(SystemService);
  private store = inject(Store);
  selectedMedia = toSignal(this.store.select(selectSelectedMediaItems));
  private sharedService = inject(SharedService);
  acceptedTypes: string[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['imageUrl']) {
      this.imageUrl = changes['imageUrl'].currentValue;
    }
    if (changes['onlyEdit']) {
      this.onlyEdit = changes['onlyEdit'].currentValue;
    }
    if (changes['ascpectRatio']) {
      this.aspectRatio = changes['aspectRatio'].currentValue;
    }
    if (changes['uploadType']?.currentValue === 'cover') {
      this.aspectRatio = 16 / 9;
    }
    if (changes['onlyEdit'] && changes['onlyEdit'].currentValue === false) {
      this.reset();
    }
    if (!changes['onlyEdit'] && this.onlyEdit === false) {
      this.reset();
    }
    this.ngOnInit();
  }

  ngOnInit(): void {
    this.acceptedTypes = this.accept.split(',').map((type) => type.split('*')[0]);
    if (this.onlyEdit) {
      this.showImageCropper = true;
    }
    if (this.imageUrl) {
      this.fileToUpload = true;
    }
    this.config = {
      aspectRatio: this.aspectRatio,
      zoomable: true,
      movable: true,
      responsive: true,
      zoom: 1,
      autoCropArea: 1,
      allowMoveImage: true,
    };
  }
  openFileInput() {
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: any) {
    const fileList: File[] = event.target.files;

    if (fileList && fileList.length > 0) {
      const files: File[] = Array.from(fileList);

      //todo: find out why the effect is not working
      this.sharedService.sharedMediaStorageCreate(files).subscribe((res: any) => {
        this.store.dispatch(addMediaToStorageSuccess({ media: res }));
      });
    }
  }
  closeMediaGallery() {
    this.mediaGalleryOpened.emit(false);
  }
  addToEditor() {
    let selected = this.selectedMedia();

    if (selected && selected.length === 1) {
      let img = selected[0].media_file_url;
      if (img && this.acceptedTypes.some((type) => img?.includes(type))) {
        let image_url = img;
        this.systemService
          .systemConvertImageUrlToBase64Create({ image_url })
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: (data) => {
              this.imageUrl = 'data:image/png;base64, ' + data.base64;
              this.showImageCropper = true;
            },
          });
      } else {
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('Error'),
          detail: this.translate.instant('The selected file type is not accepted.'),
        });
      }
    }
    this.store.dispatch(resetSelectedMediaIds());
  }

  deleteMedia() {
    this.store.dispatch(deleteSelectedMediaFromStorage());
    this.store.dispatch(resetSelectedMediaIds());
  }
  public onUploadImage(e: any): void {
    if (e.target.files && e.target.files?.length) {
      this.fileToUpload = e.target.files[0];

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageUrl = e.target.result;
      };
      reader.readAsDataURL(this.fileToUpload);
    }
  }

  resizeImage(img: any, maxWidth = 2500, maxHeight = 2500) {
    var width = img.width;
    var height = img.height;

    if (width > height) {
      if (width > maxWidth) {
        height = height * (maxWidth / width);
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width = width * (maxHeight / height);
        height = maxHeight;
      }
    }

    var canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    var ctx: any = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, width, height);
    var dataurl = canvas.toDataURL(img.type);
    return dataurl;
  }

  public exportCroppedImage() {
    this.imageDestination = this.imageCropper.cropper.getCroppedCanvas().toDataURL('image/png');
    this.showImageCropper = false;
    this.imageUrl = '';
    this.closeMediaGallery();
  }

  public cropImage(): void {
    this.imageCropper.exportCanvas();
    const croppedImgUrl = this.resizeImage(
      this.imageCropper.cropper.getCroppedCanvas(),
      this.croppedMaxWidth,
      this.croppedMaxHeight,
    );

    const tempCanvas = this.imageCropper.cropper;
    const url = this.resizeImage(
      tempCanvas.clear().getCroppedCanvas(),
      this.originalMaxWidth,
      this.originalMaxHeight,
    );
    this.ImageUploadEvent.emit({
      cropped: this.dataURLtoFile(croppedImgUrl, 'cropped.png'),
      original: this.dataURLtoFile(url, 'original.png'),
    });
  }
  dataURLtoFile(dataurl: any, filename: string) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  convertToByteArray(base64: string) {
    base64 = base64.replace(/^data:image\/(png|jpg);base64,/, '');
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  public moveUpImage(): void {
    this.imageCropper.cropper.move(0, -10);
  }

  public moveDownImage(): void {
    this.imageCropper.cropper.move(0, 10);
  }

  public moveLeftImage(): void {
    this.imageCropper.cropper.move(-10, 0);
  }

  public moveRightImage(): void {
    this.imageCropper.cropper.move(10, 0);
  }

  public rotateLeftImage(): void {
    this.imageCropper.cropper.rotate(-90);
  }

  public rotateRightImage(): void {
    this.imageCropper.cropper.rotate(90);
  }

  public resetCropper(): void {
    this.imageCropper.cropper.reset();
  }

  public clickThroughUpload(): void {
    this.uploadButton.nativeElement.click();
  }

  public reset(): void {
    this.imageDestination = null;
    this.fileToUpload = null;
    this.showImageCropper = false;
    this.imageUrl = '';
  }

  ngOnDestroy(): void {
    if (this.imageCropper && this.imageCropper.cropper) this.imageCropper.cropper.reset();
  }
}
