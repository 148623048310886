import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  filter,
  from,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';

import { SharedService, UploadService, FileManagerService, DriveService } from '@offconon/core-api';

import * as MediaGalleryActions from './media-gallery.actions';
import { selectSelectedMediaIds } from './media-gallery.selectors';

@Injectable()
export class MediaGalleryEffects {
  private actions$ = inject(Actions);
  private sharedService = inject(SharedService);
  private driveService = inject(DriveService);
  private fileManagerService = inject(FileManagerService);
  private uploadService = inject(UploadService);
  private store = inject(Store);

  fetchDriveFolders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaGalleryActions.fetchDriveFolders),
      switchMap((action) => {
        return this.driveService
          .driveAlbumList(action.page, action.pageSize, action.searchJson)
          .pipe(
            switchMap((storage) => {
              return of(MediaGalleryActions.fetchDriveFoldersSuccess({ driveGallery: storage }));
            }),
          );
      }),
    ),
  );
  fetchStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaGalleryActions.fetchStorage),
      switchMap((action) => {
        return this.sharedService
          .sharedMediaStorageList(action.page, action.pageSize, action.searchJson)
          .pipe(
            switchMap((storage) => {
              return of(MediaGalleryActions.fetchStorageSuccess({ mediaGallery: storage }));
            }),
          );
      }),
    ),
  );
  /*fetchAlbumItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaGalleryActions.fetchAlbumItems),
      switchMap((action) => {
        return this.uploadService
          .uploadAlbumItemsList(action.page, action.pageSize, action.searchJson)
          .pipe(
            switchMap((storage) => {
              return of(MediaGalleryActions.fetchAlbumItemsSuccess({ mediaGallery: storage }));
            }),
          );
      }),
    ),
  );*/
  fetchAlbumItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaGalleryActions.fetchAlbumItems),
      switchMap((action) => {
        return this.driveService
          .driveAlbumItemList(action.page, action.pageSize, action.searchJson)
          .pipe(
            switchMap((storage) => {
              return of(MediaGalleryActions.fetchAlbumItemsSuccess({ mediaGallery: storage }));
            }),
          );
      }),
    ),
  );
  moveMediaItemFromAlbum$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaGalleryActions.moveMediaItemFromAlbum),
      switchMap((action) => {
        return this.driveService
          .driveMoveCreate({
            album_item_id_items: action.album_item_id_items,
            album_id_items: action.album_id_items,
            parent_id: action.parent_id,
          })
          .pipe(
            switchMap((res) => {
              return of(
                MediaGalleryActions.deleteMediaItemFromAlbumSuccessArray({
                  id: action.album_item_id_items,
                }),
                MediaGalleryActions.fetchDriveFolders({
                  page: 1,
                  pageSize: 100,
                  searchJson: {
                    user_id: action.user_id,
                    business_id: action.business_id,
                  },
                }),
              );
            }),
          );
      }),
    ),
  );
  fetchStorageDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaGalleryActions.fetchStorageDetails),
      switchMap((action) => {
        return this.fileManagerService.fileManagerCloudStorageRetrieve().pipe(
          switchMap((storage) => {
            return of(MediaGalleryActions.fetchStorageDetailsSuccess({ storageDetails: storage }));
          }),
        );
      }),
    ),
  );
  getRestorableItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaGalleryActions.getRestorableItems),
      switchMap((action) => {
        return this.driveService
          .driveRestoreList(action.page, action.pageSize, action.searchJson)
          .pipe(
            switchMap((storage) => {
              return of(
                MediaGalleryActions.getRestorableItemsSuccess({ restorableItems: storage }),
              );
            }),
          );
      }),
    ),
  );
  /*restoreItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaGalleryActions.restoreItems),
      switchMap((action) => {
        return this.driveService.driveRestoreUpdate().pipe(
          switchMap((storage) => {
            return of(
              MediaGalleryActions.restoreItemsSuccess({
                albumIdItems: action.albumIdItems || [],
                albumItemIdItems: action.albumItemIdItems || [],
              }),
            );
          }),
        );
      }),
    ),
  ); */

  /*restoreItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaGalleryActions.restoreItems),
      switchMap((action) => {
        const albumIdItems = action?.albumIdItems || [];
        const albumItemIdItems = action?.albumItemIdItems || [];

        const albumIdRestore$ =
          albumIdItems.length > 0
            ? from(albumIdItems).pipe(
                concatMap((id: any) =>
                  // /driveRestoreUpdate
                  this.driveService.driveRestoreCreate(id, undefined).pipe(
                    map(() => {
                      return MediaGalleryActions.restoreItemsSuccess({
                        albumIdItems: [id],
                        albumItemIdItems: [],
                      });
                    }),
                    catchError((err) => {
                      console.error('Error restoring ALBUM ID:', id, err);
                      return of(MediaGalleryActions.restoreItemsFailure({ error: err }));
                    }),
                  ),
                ),
              )
            : of();

        const albumItemIdRestore$ =
          albumItemIdItems.length > 0
            ? from(albumItemIdItems).pipe(
                concatMap((id: any) =>
                  //driveRestoreUpdate
                  this.driveService.driveRestoreCreate(undefined, id).pipe(
                    map(() => {
                      return MediaGalleryActions.restoreItemsSuccess({
                        albumIdItems: [],
                        albumItemIdItems: [id],
                      });
                    }),
                    catchError((err) => {
                      console.error('Error restoring ITEM ID:', id, err);
                      return of(MediaGalleryActions.restoreItemsFailure({ error: err }));
                    }),
                  ),
                ),
              )
            : of();

        return concat(albumIdRestore$, albumItemIdRestore$).pipe(
          map(() =>
            MediaGalleryActions.restoreItemsSuccess({
              albumIdItems: action.albumIdItems,
              albumItemIdItems: action.albumItemIdItems,
            }),
          ),
          catchError((error) => of(MediaGalleryActions.restoreItemsFailure({ error }))),
        );
      }),
    ),
  );*/
  restoreItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaGalleryActions.restoreItems),
      switchMap((action) => {
        const albumIdItems = action?.albumIdItems || [];
        const albumItemIdItems = action?.albumItemIdItems || [];

        return this.driveService
          .driveRestoreCreate({
            album_id_items: albumIdItems,
            album_item_id_items: albumItemIdItems,
          })
          .pipe(
            map(() => {
              return MediaGalleryActions.restoreItemsSuccess({
                albumIdItems: albumIdItems,
                albumItemIdItems: albumItemIdItems,
              });
            }),
            catchError((err) => {
              return of(MediaGalleryActions.restoreItemsFailure({ error: err }));
            }),
          );
      }),
    ),
  );

  fetchFolders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaGalleryActions.fetchFolders),
      switchMap((action) => {
        return this.uploadService
          .uploadAlbumTemplatePathList(action.page, action.pageSize, action.searchJson)
          .pipe(
            switchMap((storage) => {
              return of(MediaGalleryActions.fetchFolderSuccess({ folders: storage }));
            }),
          );
      }),
    ),
  );
  addMediaToStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaGalleryActions.addMediaToStorage),
      switchMap((action) => {
        return this.sharedService.sharedMediaStorageCreate(action.files).pipe(
          map((storage: any) => {
            return MediaGalleryActions.addMediaToStorageSuccess({ media: storage });
          }),
          catchError((error) => {
            return of(MediaGalleryActions.addMediaToStorageFailure({ error: error.message }));
          }),
        );
      }),
    ),
  );

  deleteSelectedMediaFromStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaGalleryActions.deleteSelectedMediaFromStorage),
      withLatestFrom(this.store.select(selectSelectedMediaIds)),
      filter(([_, selectedIds]) => selectedIds.length > 0),
      mergeMap(([action, selectedIds]) =>
        from(selectedIds).pipe(
          mergeMap((id) =>
            this.uploadService.uploadUploadContentDestroy(id).pipe(
              map(() => MediaGalleryActions.deleteMediaFromStorageSuccess({ id: id })),
              catchError((error) => {
                return of();
              }),
            ),
          ),
        ),
      ),
    ),
  );
  deleteSelectedMediaFromAlbum$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaGalleryActions.deleteSelectedMediaFromAlbum),
      switchMap((media_file_id: any) =>
        this.uploadService.uploadUploadContentDestroy(media_file_id as number).pipe(
          map(() => MediaGalleryActions.deleteMediaFromAlbumSuccess(media_file_id)),
          catchError((error) => {
            return of();
          }),
        ),
      ),
    ),
  );
  deleteMediaItemFromAlbum$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaGalleryActions.deleteMediaItemFromAlbum),
      tap((action: any) => {}),
      switchMap((action: any) =>
        //driveAlbumItemDestroy
        this.driveService.driveAlbumItemCreate({ ids: action.id }).pipe(
          map(() => MediaGalleryActions.deleteMediaItemFromAlbumSuccessArray({ id: action.id })),
          catchError((error) => {
            return of();
          }),
        ),
      ),
    ),
  );
  /*deleteMediaItemFromAlbum$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaGalleryActions.deleteMediaItemFromAlbum),
      tap((action: any) => {}),
      switchMap((action: any) =>
        from(action.id).pipe(
          concatMap((id: any) =>
            //driveAlbumItemDestroy
            this.driveService.driveAlbumDestroy(undefined, undefined, id, undefined).pipe(
              map(() => MediaGalleryActions.deleteMediaItemFromAlbumSuccess({ id: id })),
              catchError((error) => {
                return of();
              }),
            ),
          ),
        ),
      ),
    ),
  );*/

  deleteSelectedFolderFromStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaGalleryActions.deleteSelectedFolderFromStorage),
      switchMap((action) =>
        this.driveService.driveAlbumDestroy(undefined, action.id).pipe(
          switchMap(() => [
            MediaGalleryActions.deleteFolderFromStorageSuccess({
              id: action.id,
            }),
          ]),
        ),
      ),
    ),
  );
}
