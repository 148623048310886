import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, Input, inject, Output, EventEmitter, OnInit, DestroyRef } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { PaginatorModule } from 'primeng/paginator';
import { TooltipModule } from 'primeng/tooltip';
import { Subscription } from 'rxjs';

import { DriveService, PaginatedAlbumTemplateRecursiveList } from '@offconon/core-api';

import { deleteMediaItemFromAlbum } from '../../../store/media-gallery.actions';
import { FolderTreeComponent } from '../folder-tree/folder-tree.component';

@Component({
  selector: 'offconon-media-folder-content-card',
  imports: [
    CommonModule,
    TranslateModule,
    PaginatorModule,
    DragDropModule,
    MatIcon,
    TooltipModule,
    ButtonModule,
    MenuModule,
    ConfirmDialogModule,
    FolderTreeComponent,
    DialogModule,
  ],
  templateUrl: './media-folder-content-card.component.html',
  styles: ``,
})
export class MediaFolderContentCardComponent implements OnInit {
  @Input() multiple = false;
  @Input() newFiles = {};
  @Input() folderList: PaginatedAlbumTemplateRecursiveList | undefined;
  @Input() media: any;
  @Input() user_id: number = 0;
  @Input() selectedChildren: any[] = [];
  @Input() selectedChildrenParents: any[] = [];
  @Input() business_id: any = null;
  @Input() album_id: number = 0;
  @Input() showType: 'all' | 'image' | 'video' | 'audio' | 'document' | 'zip' | 'other' = 'all';
  @Output() selectedItemEmit = new EventEmitter<any>();
  @Output() selectedFolderIdEmit = new EventEmitter<any>();
  @Output() selectedFolderEmit = new EventEmitter<any>();
  @Output() EditImageEmit = new EventEmitter<any>();
  @Output() ResetSelectedMediaEmmit = new EventEmitter<any>();

  private messageService = inject(MessageService);
  private store = inject(Store);
  private translateService = inject(TranslateService);
  private confirmationService = inject(ConfirmationService);
  private translate = inject(TranslateService);
  private driveService = inject(DriveService);
  private destroyRef = inject(DestroyRef);

  items: MenuItem[];
  pageSize = 10;
  page: number = 1;
  loading = false;
  selectFolder = false;
  private paginatedSubscription: Subscription;
  public datas: any;
  public results: any;
  public selectedIds: number[] = [];
  public selectedItem: any[] = [];

  ngOnInit(): void {
    this.datas = [];
    this.results = [];
    this.renderMenu(this.media);
  }

  closeDialog() {
    this.selectFolder = false;
  }

  editImage(url: any) {
    this.EditImageEmit.emit(url);
  }
  deleteMedia() {
    if (this.media) {
      let media_ids: any[] = [];
      media_ids.push(this.media.id);
      this.confirmationService.confirm({
        message: this.translate.instant('Are you sure you want to delete this?'),
        header: this.translate.instant('Attention'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.store.dispatch(deleteMediaItemFromAlbum({ id: media_ids }));
          this.messageService.add({
            severity: 'info',
            summary: this.translate.instant('Successful'),
            detail: this.translate.instant('Your request has been successfully completed.'),
          });
        },
        key: 'deleteMediaDialogCard-' + this.media.id,
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: this.translate.instant('Error'),
        detail: this.translate.instant('No selected item found!'),
      });
    }
  }
  showFolders() {
    this.selectFolder = true;
  }
  renderMenu(data: any) {
    this.items = [
      {
        label: this.translateService.instant('Manage'),
        visible: data,
        items: [
          {
            label: this.translateService.instant('Edit'),
            icon: 'edit',
            visible: data.media_file_type === 'image',
            command: () => {
              this.editImage(data);
            },
          },
          {
            label: this.translateService.instant('Download'),
            icon: 'download',
            visible: data,
            command: () => {
              //this.delete();
            },
          },
          {
            label: this.translateService.instant('Move'),
            icon: 'send',
            visible: data,
            command: () => {
              this.showFolders();
            },
          },
          {
            label: this.translateService.instant('Delete'),
            icon: 'delete',
            icon_color: 'text-red-500',
            visible: data,
            command: () => {
              this.deleteMedia();
            },
          },
        ],
      },
    ];
  }
}
