import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { PaginatorModule } from 'primeng/paginator';
import { TooltipModule } from 'primeng/tooltip';

import { PaginatedAlbumTemplateRecursiveList } from '@offconon/core-api';

@Component({
  selector: 'offconon-folder-breadcrumb',
  templateUrl: './folder-breadcrumb.component.html',
  providers: [ConfirmationService],
  imports: [
    CommonModule,
    TranslateModule,
    PaginatorModule,
    MatIcon,
    TooltipModule,
    ButtonModule,
    DividerModule,
    DialogModule,
  ],
  styles: ``,
})
export class FolderBreadcrumbComponent {
  @Input() multiple = false;
  @Input() user_id: number = 0;
  @Input() parents: any[];
  @Input() folderList: PaginatedAlbumTemplateRecursiveList | undefined;
  @Input() business_id: number;
  @Input() selected_folder_id: number;
  @Input() selectedChildrenParents: any[] = [];
  @Input() currentFolder: any = null;
  @Output() selectedIdChangeEmit = new EventEmitter<any>();

  pageSize = 100;
  selectedId: any = undefined;
  addFolder = false;

  foldersWithChildren: any[] = [];

  selectedIdChange(event: any) {
    this.selectedId = event;
    this.selectedIdChangeEmit.emit(event);
  }
}
