<li *ngIf="folder" class="items-center">
  <div class="flex items-center">
    <div
      class="flex items-center cursor-pointer"
      [ngClass]="{
        'text-blue-500': folder.id === selectedId,
        'text-red-500': selected_file_album_id === folder.id
      }"
      (click)="selectItem(folder.id)">
      <mat-icon
        class="mr-1"
        [fontIcon]="
          folder.id === selectedId || folder.id === selected_file_album_id
            ? 'folder_open'
            : 'folder'
        " />
      <span
        >{{ folder.name }}
        <span *ngIf="selected_file_album_id === folder.id">({{ 'current' | translate }})</span>
      </span>
    </div>
  </div>

  <ul *ngIf="folder.children && folder.children.length > 0">
    <li *ngFor="let child of folder.children" class="ml-3">
      <offconon-folder-tree-item
        [folder]="child"
        [user_id]="user_id"
        [business_id]="business_id"
        [selected_file_album_id]="selected_file_album_id"
        [selectedId]="selectedId"
        (selectedIdEmit)="selectedIdEmit.emit($event)" />
    </li>
  </ul>
</li>
