import {
  CdkDragDrop,
  moveItemInArray,
  CdkDropList,
  CdkDrag,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DividerModule } from 'primeng/divider';
import { DrawerModule } from 'primeng/drawer';

import { PipelineStepStageColumnCommonFormComponent } from '@offconon/shared/ui/pipeline-step-stage-column-common-form';

import { StepStageColumnComponent } from '../../stage/step-stage-column/step-stage-column.component';

@Component({
  selector: 'offconon-steps-columns',
  templateUrl: './steps-columns.component.html',
  styleUrl: './steps-columns.component.scss',
  providers: [ConfirmationService],
  imports: [
    CdkDropList,
    CdkDrag,
    StepStageColumnComponent,
    ButtonModule,
    ConfirmDialogModule,
    TranslateModule,
    CommonModule,
    DrawerModule,
    PipelineStepStageColumnCommonFormComponent,
    DividerModule,
    RouterModule,
  ],
})
export class StepsColumnsComponent {
  @Input({ required: true }) createButton: TemplateRef<any>;

  @Input() pipeline: any;
  @Input() cardTemplate: TemplateRef<any>;
  @Output() cardMovedEvent = new EventEmitter<any>();
  @Output() columnMovedEvent = new EventEmitter<any>();

  openDrawer: boolean = false;
  stepData: any;

  dropCard(event: CdkDragDrop<any[]>, stepId: number) {
    if (event.previousContainer === event.container) {
      const asd = {
        task: {
          service_id: event.container.data[event.previousIndex]?.id,
          order_id: event.currentIndex,
          step_id: stepId,
        },
      };

      this.cardMovedEvent.emit(asd);

      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      this.cardMovedEvent.emit({
        task: {
          service_id: event.previousContainer.data[event.previousIndex].id,
          order_id: event.currentIndex,
          step_id: stepId,
        },
        prevContainerId: event.previousContainer.data[event.previousIndex].step_id,
      });
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  dropColumn(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.pipeline?.steps, event.previousIndex, event.currentIndex);

    this.columnMovedEvent.emit({
      id: event.container.data[event.currentIndex].id,
      order_id: event.currentIndex,
    });
  }

  getConnectedList(): string[] {
    return this.pipeline.steps.map((column: any) => column.name);
  }

  editStep(stepData: any) {
    this.openDrawer = true;
    this.stepData = stepData;
  }
}
