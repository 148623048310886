@if (!imageUrl && !showImageCropper) {
  <div class="flex flex-col">
    <div class="flex flex-row flex-wrap gap-3 my-3">
      <p-button (click)="openFileInput()">{{ 'Upload new image' | translate }}</p-button>
      <p-button *ngIf="useWithCropper" (click)="addToEditor()">{{
        'Edit image' | translate
      }}</p-button>
      <p-button *ngIf="!useWithCropper" (click)="addToMedia()">{{
        'Save image' | translate
      }}</p-button>
      <p-button icon="pi pi-trash" [severity]="'danger'" [link]="true" (click)="deleteMedia()">{{
        'Delete' | translate
      }}</p-button>
      <p-select optionValue="code" optionLabel="name" [options]="options" [(ngModel)]="showType" />
    </div>
    <offconon-media-gallery-content [showType]="showType" />
  </div>
  <input #fileInput type="file" style="display: none" multiple (change)="onFileSelected($event)" />
}
@if (imageUrl && showImageCropper) {
  <div class="pt-2">
    <div class="flex justify-center flex-wrap pb-2 mb-2">
      <div class="flex items-center justify-center">
        <div class="p-2 text-center">
          <offconon-shared-ui-image-cropper
            #image
            [cropperOptions]="config"
            [imageUrl]="imageUrl"
            (export)="exportCroppedImage()" />
        </div>
      </div>
    </div>

    <div class="pb-2 mb-2 ml-2 mr-2 flex flex-wrap gap-3 justify-evenly">
      <button
        pButton
        icon="pi pi-check"
        iconPos="left"
        class="px-5 py-3 p-button-outlined p-button-success p-button-rounded font-bold w-auto white-space-nowrap"
        style="white-space: nowrap"
        label="{{ 'Save' | translate }}"
        (click)="cropImage()"></button>

      <button
        pButton
        icon="pi pi-caret-up"
        iconPos="left"
        class="px-5 py-3 p-button-outlined p-button-rounded font-bold w-auto white-space-nowrap"
        style="white-space: nowrap"
        label="{{ 'Move up' | translate }}"
        (click)="moveUpImage()"></button>

      <button
        pButton
        icon="pi pi-caret-down"
        iconPos="left"
        class="px-5 py-3 p-button-outlined p-button-rounded font-bold w-auto white-space-nowrap"
        style="white-space: nowrap"
        label="{{ 'Move down' | translate }}"
        (click)="moveDownImage()"></button>

      <button
        pButton
        icon="pi pi-caret-left"
        iconPos="left"
        class="px-5 py-3 p-button-outlined p-button-rounded font-bold w-auto white-space-nowrap"
        style="white-space: nowrap"
        label="{{ 'Move left' | translate }}"
        (click)="moveLeftImage()"></button>

      <button
        pButton
        icon="pi pi-caret-right"
        iconPos="left"
        class="px-5 py-3 p-button-outlined p-button-rounded font-bold w-auto white-space-nowrap"
        style="white-space: nowrap"
        label="{{ 'Move right' | translate }}"
        (click)="moveRightImage()"></button>

      <button
        pButton
        icon="pi pi-undo"
        iconPos="left"
        class="px-5 py-3 p-button-outlined p-button-rounded font-bold w-auto white-space-nowrap"
        style="white-space: nowrap"
        label="{{ 'Rotate left' | translate }}"
        (click)="rotateLeftImage()"></button>

      <button
        pButton
        icon="pi pi-refresh"
        iconPos="left"
        class="px-5 py-3 p-button-outlined p-button-rounded font-bold w-auto white-space-nowrap"
        style="white-space: nowrap"
        label="{{ 'Rotate right' | translate }}"
        (click)="rotateRightImage()"></button>

      <button
        pButton
        icon="pi pi-sync"
        iconPos="left"
        class="px-5 py-3 p-button-outlined p-button-warning p-button-rounded font-bold w-auto white-space-nowrap"
        style="white-space: nowrap"
        label="{{ 'Reset' | translate }}"
        (click)="resetCropper()"></button>

      <button
        pButton
        icon="pi pi-times"
        iconPos="left"
        class="px-5 py-3 p-button-outlined p-button-danger p-button-rounded font-bold w-auto white-space-nowrap"
        style="white-space: nowrap"
        label="{{ 'Delete' | translate }}"
        (click)="reset()"></button>
    </div>
  </div>
}
