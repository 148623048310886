<li *ngIf="folder" class="items-center">
  <div
    class="flex items-center"
    [draggable]="false"
    (dragover)="allowDrop($event)"
    (dragleave)="onDragLeave($event)"
    (drop)="onDrop($event, folder)">
    <div
      class="flex cursor-pointer hover:text-gray-500 {{ isHovering ? 'text-gray-500' : '' }}"
      [ngClass]="{ 'text-blue-500': folder.id === selectedId }"
      (click)="selectItem(folder.id)">
      <mat-icon class="mr-1" fontIcon="{{ folder.id === selectedId ? 'folder_open' : 'folder' }}" />
      <span>{{ folder.name }}</span>
    </div>
    <span *ngIf="folder.children && folder.children.length > 0">
      <button type="button" class="ml-2" (click)="isOpen = !isOpen">
        <mat-icon fontIcon="{{ isOpen ? 'arrow_drop_up' : 'arrow_drop_down' }}" />
      </button>
    </span>
  </div>

  <ul *ngIf="isOpen && folder.children && folder.children.length > 0">
    <li *ngFor="let child of folder.children" class="ml-3">
      <offconon-folder-item
        [folder]="child"
        [selectedId]="selectedId"
        [user_id]="user_id"
        [business_id]="business_id"
        [selectedChildrenParents]="selectedChildrenParents"
        (selectedIdEmit)="selectedIdEmit.emit($event)" />
    </li>
  </ul>
</li>
