import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

import { moveMediaItemFromAlbum } from '../../../store/media-gallery.actions';

@Component({
  selector: 'offconon-folder-item',
  templateUrl: './folder-item.component.html',
  imports: [CommonModule, MatIcon, DragDropModule],
  //styleUrls: ['./folder-item.component.css'],
})
export class FolderItemComponent implements OnChanges {
  @Input() folder: any;
  @Input() selectedId: any;
  @Input() isOpen = false;
  @Input() business_id: number;
  @Input() user_id: number;
  @Input() selectedChildrenParents: any[] = [];
  @Output() selectedIdEmit = new EventEmitter<any>();
  @Output() updateFoldersEmit = new EventEmitter<any>();

  private store = inject(Store);
  private messageService = inject(MessageService);
  private translateService = inject(TranslateService);

  isHovering = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.selectedChildrenParents && this.selectedChildrenParents.length > 0) {
      this.checkIsOpen();
    }
    this.selectedIdEmit.emit(this.selectedId);
  }
  checkIsOpen() {
    this.isOpen = this.selectedChildrenParents.some((parent) => parent.id === this.folder.id);
  }
  allowDrop(event: DragEvent) {
    this.isHovering = true;
    event.preventDefault();
  }
  onDragLeave(event: DragEvent): void {
    this.isHovering = false;
  }

  onDrop(event: DragEvent, targetFolder: any) {
    event.preventDefault();
    const mediaId = Number(event.dataTransfer?.getData('mediaId')) || 0;
    const albumId = Number(event.dataTransfer?.getData('albumId')) || 0;
    const mediaType = event.dataTransfer?.getData('mediaType');
    const mIds: number[] = [];
    const aIds: number[] = [];
    if (mediaId > 0) {
      mIds.push(mediaId);
    }
    if (albumId > 0) {
      aIds.push(albumId);
    }
    if (albumId !== targetFolder.id) {
      this.store.dispatch(
        moveMediaItemFromAlbum({
          user_id: this.user_id,
          business_id: this.business_id,
          album_item_id_items: mIds,
          album_id_items: aIds,
          parent_id: targetFolder.id,
        }),
      );
    } else {
      this.messageService.add({
        severity: 'info',
        summary: this.translateService.instant('Error'),
        detail: this.translateService.instant('The folder is can not move to itself!'),
      });
    }
  }

  selectItem(id: any) {
    this.selectedIdEmit.emit(id);
  }
}
