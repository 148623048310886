import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { PaginatorModule } from 'primeng/paginator';
import { TooltipModule } from 'primeng/tooltip';

import { PaginatedAlbumTemplateRecursiveList } from '@offconon/core-api';

import { moveMediaItemFromAlbum } from '../../../store/media-gallery.actions';
import { FolderTreeItemComponent } from './folder-tree-item.component';
@Component({
  selector: 'offconon-folder-tree',
  templateUrl: './folder-tree.component.html',
  imports: [
    CommonModule,
    TranslateModule,
    PaginatorModule,
    TooltipModule,
    ButtonModule,
    DividerModule,
    DialogModule,
    FolderTreeItemComponent,
  ],
  styles: ``,
})
export class FolderTreeComponent {
  @Input() user_id: number = 0;
  @Input() folderList: PaginatedAlbumTemplateRecursiveList | undefined;
  @Input() business_id: number;
  @Input() selected_file_id: number;
  @Input() selected_file_ids: number[];
  @Input() selected_file_album_id: number;
  @Output() MovedFilesEmit = new EventEmitter<any>();

  private store = inject(Store);
  private translateService = inject(TranslateService);
  private messageService = inject(MessageService);

  selectedId: any = undefined;

  selectedIdChange(event: any) {
    this.selectedId = event;
  }
  moveFile() {
    if (this.selectedId) {
      let mIds: number[] = [];
      let aIds: number[] = [];
      if (this.selected_file_ids) {
        mIds = this.selected_file_ids;
      } else {
        let mediaId = 0;
        let albumId = 0;
        mediaId = this.selected_file_id;
        if (mediaId > 0) {
          mIds.push(mediaId);
        }
        if (albumId > 0) {
          aIds.push(albumId);
        }
      }

      if (this.selected_file_album_id !== this.selectedId) {
        this.store.dispatch(
          moveMediaItemFromAlbum({
            user_id: this.user_id,
            business_id: this.business_id,
            album_item_id_items: mIds,
            album_id_items: aIds,
            parent_id: this.selectedId,
          }),
        );
      } else {
        this.messageService.add({
          severity: 'info',
          summary: this.translateService.instant('Error'),
          detail: this.translateService.instant('The file is already in this folder!'),
        });
      }
      setTimeout(() => {
        this.MovedFilesEmit.emit(true);
      }, 500);
    } else {
      this.messageService.add({
        severity: 'info',
        summary: this.translateService.instant('Error'),
        detail: this.translateService.instant('Please select a folder!'),
      });
    }
  }
}
