<div>
  <div class="flex gap-2 mt-4">
    <a class="cursor-pointer" (click)="addFolder = !addFolder"
      ><mat-icon
        class="mr-1"
        fontIcon="create_new_folder"
        tooltipPosition="bottom"
        pTooltip="{{ 'Add new folder' | translate }}"
    /></a>
    <a
      *ngIf="selectedId && selectedId !== 'Recycle' && selectedId !== rootFolders[0].id"
      class="cursor-pointer"
      (click)="editFolder = !editFolder"
      ><mat-icon
        class="mr-1"
        fontIcon="edit_folder"
        tooltipPosition="bottom"
        pTooltip="{{ 'Edit folder' | translate }}"
    /></a>
    <a
      *ngIf="selectedId && selectedId !== 'Recycle' && selectedId !== rootFolders[0].id"
      class="cursor-pointer"
      (click)="deleteFolder()"
      ><mat-icon
        class="mr-1"
        fontIcon="delete"
        tooltipPosition="bottom"
        pTooltip="{{ 'Delete selected folder' | translate }}"
    /></a>
    <a class="cursor-pointer" (click)="updateFolders()"
      ><mat-icon
        class="mr-1"
        fontIcon="restore"
        tooltipPosition="bottom"
        pTooltip="{{ 'Refresh' | translate }}"
    /></a>
  </div>
  <p-dialog
    header="{{ 'Add new folder' | translate }}"
    [modal]="true"
    [closeOnEscape]="true"
    [resizable]="true"
    [closable]="true"
    [dismissableMask]="true"
    [maximizable]="true"
    [style]="{ width: '50rem' }"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
    [(visible)]="addFolder"
    (onHide)="closeDialog()">
    <div class="p-2">
      <div class="pt-3">
        @if (addFolder) {
          <offconon-media-folder-form-common
            [user_id]="user_id"
            [album_id]="selectedId"
            [business_id]="business_id"
            (formSavedEvent)="savedForm($event)" />
        }
      </div>
    </div>
  </p-dialog>
  <p-dialog
    header="{{ 'Edit folder' | translate }}"
    [modal]="true"
    [closeOnEscape]="true"
    [resizable]="true"
    [closable]="true"
    [dismissableMask]="true"
    [maximizable]="true"
    [style]="{ width: '50rem' }"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
    [(visible)]="editFolder"
    (onHide)="closeEditDialog()">
    <div class="p-2">
      <div class="pt-3">
        @if (editFolder) {
          <offconon-media-folder-form-common
            [user_id]="user_id"
            [edit_id]="selectedId"
            [album_id]="selectedId"
            [name]="currentFolder.name"
            [business_id]="business_id"
            (formSavedEvent)="savedForm($event)" />
        }
      </div>
    </div>
  </p-dialog>
  <div class="my-3">
    <div class="font-bold mb-2">
      {{ business_id ? ('Business' | translate) : ('Personal' | translate) }}
      {{ 'Account' | translate }}
    </div>
    <ul class="flex flex-col gap-2">
      <li *ngFor="let folder of folderList?.results" class="cursor-pointer flex items-center">
        @if (!folder.album_template?.parent_id) {
          <offconon-folder-item
            [folder]="folder"
            [isOpen]="true"
            [selectedId]="selectedId"
            [user_id]="user_id"
            [business_id]="business_id"
            [selectedChildrenParents]="selectedChildrenParents"
            (selectedIdEmit)="selectedIdChange($event)" />
        }
      </li>
      <li
        class="cursor-pointer flex items-center {{
          selectedId === 'Recycle' ? 'text-primary' : ''
        }}"
        (click)="selectedIdChange('Recycle')">
        <mat-icon class="mr-1" fontIcon="folder" /><span>{{ 'Recycled Bin' | translate }}</span>
      </li>
    </ul>
  </div>
</div>
<p-confirmDialog
  key="deletefolderDialog"
  [breakpoints]="{ '960px': '75vw' }"
  [baseZIndex]="12"
  [appendTo]="'body'">
  <ng-template #headless let-message let-onAccept="onAccept" let-onReject="onReject">
    <div class="flex flex-col items-center p-8 bg-surface-0 dark:bg-surface-900 rounded">
      <span class="font-bold text-2xl block mb-2 mt-6">{{ message?.header }}</span>
      <p class="mb-0">{{ message?.message }}</p>
      <div class="flex items-center gap-2 mt-6">
        <p-button label="{{ 'Yes, I want to delete it.' | translate }}" (onClick)="onAccept()" />
        <p-button label="{{ 'Cancel' | translate }}" [outlined]="true" (onClick)="onReject()" />
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
