<div class="columns-3xs columns-break gap-4 space-y-4">
  <div>
    @for (child of selectedChildren; track child.id) {
      <div
        class="flex flex-col justify-center items-center p-3 cursor-pointer"
        [draggable]="true"
        (dragstart)="onDragStart($event, child)"
        (click)="toggleSelection(child.id || 0)"
        (click)="selectedFolderId(child)">
        <div class="flex flex-col justify-center items-center gap-3 min-h-44">
          <div><mat-icon class="big-icon" fontIcon="folder" /></div>
          <div>{{ child.name || child.id }}</div>
        </div>
      </div>
    }
    @for (media of results; track media.id) {
      <div
        [draggable]="true"
        (dragstart)="onDragStart($event, media)"
        (click)="toggleSelection(media.id || 0)">
        <offconon-media-folder-content-card
          [media]="media"
          [folderList]="folderList"
          (EditImageEmit)="editImage($event)"
          (ResetSelectedMediaEmmit)="resetSelected($event)" />
      </div>
    } @empty {
      <div>No media items available.</div>
    }
  </div>
</div>
@if (
  (datas?.total_objects || 0) > pageSize && loading === false && (datas?.total_pages || 0) > page
) {
  <div #moreItem style="height: 50px; width: 100%"></div>
}
@if ((datas?.total_objects || 0) > pageSize && loading === false && datas?.total_pages === page) {
  <div class="flex items-center justify-center">
    <p class="text-xs text-500 ml-1 pt-1 text-gray">
      {{ 'No more items' | translate }}
    </p>
  </div>
}
