@if (loading) {
  <div
    class="justify-center items-center absolute !z-50 w-full h-full !bg-black !opacity-95 top-0 left-0">
    <div class="pt-7">
      <offconon-loading-loader-triangle />
    </div>
  </div>
}
@if (!imageUrl && !showImageCropper) {
  <div class="flex flex-col box-bg p-4 rounded-md">
    <div *ngIf="storageDetails()" class="font-bold">
      {{ 'Usage' | translate }}:
      {{ (storageDetails()?.stored_size || 0) / 1000000000 | number: '1.2-2' }} GB /
      {{ (storageDetails()?.storage_size || 0) / 1000000000 | number: '1.2-2' }} GB
    </div>
    <div class="my-3">
      <p-metergroup [value]="storage_values" />
    </div>
    <div class="flex flex-row flex-wrap gap-3 my-3">
      <button
        pButton
        size="small"
        severity="primary"
        class="px-5 py-3 p-button-rounded font-bold w-auto white-space-nowrap"
        style="white-space: nowrap"
        icon="{{ showUploader ? 'pi pi-times' : 'pi pi-upload' }}"
        [type]="'button'"
        label="{{
          showUploader ? ('Close uploader' | translate) : ('Upload new file' | translate)
        }}"
        (click)="showUploader = !showUploader">
        <div *ngIf="loading">
          <p-progressSpinner aria-label="Loading" [style]="{ height: '15px' }" />
        </div>
      </button>
      <button
        *ngIf="selectedMedia[0]?.length > 0"
        pButton
        size="small"
        severity="secondary"
        icon="pi pi-send"
        class="px-5 py-3 p-button-rounded font-bold w-auto white-space-nowrap"
        style="white-space: nowrap"
        [type]="'button'"
        label="{{ 'Move selected' | translate }}"
        (click)="moveSelected()"></button>
      <button
        pButton
        size="small"
        severity="secondary"
        icon="pi pi-trash"
        class="px-5 py-3 p-button-rounded font-bold w-auto white-space-nowrap"
        style="white-space: nowrap"
        [type]="'button'"
        label="{{ 'Recycled bin' | translate }}"
        (click)="selectedFolderId('Recycle')"></button>
      @if (folderId !== 'Recycle') {
        <!-- <button
          pButton
          size="small"
          severity="secondary"
          class="px-5 py-3 p-button-rounded font-bold w-auto white-space-nowrap"
          style="white-space: nowrap"
          [type]="'button'"
          label="{{ canSelect ? ('Edit Image' | translate) : ('Edit selected' | translate) }}"
          (click)="addToEditor()"></button> -->
        @if (canSelect) {
          <button
            pButton
            size="small"
            severity="secondary"
            class="px-5 py-3 p-button-rounded font-bold w-auto white-space-nowrap"
            style="white-space: nowrap"
            [type]="'button'"
            label="{{ 'Select media' | translate }}"
            (click)="addToMedia()"></button>
        }
        <button
          pButton
          size="small"
          severity="danger"
          class="px-5 py-3 p-button-rounded font-bold w-auto white-space-nowrap"
          style="white-space: nowrap"
          link="true"
          icon="pi pi-trash"
          [type]="'button'"
          (click)="deleteMedia()"></button>
        <p-select
          optionValue="code"
          optionLabel="name"
          [options]="options"
          [(ngModel)]="showType" />
      } @else {
        <button
          pButton
          size="small"
          severity="secondary"
          class="px-5 py-3 p-button-rounded font-bold w-auto white-space-nowrap"
          style="white-space: nowrap"
          [type]="'button'"
          label="{{ 'Restore selected' | translate }}"
          (click)="restoreRecycled()"></button>
        <button
          pButton
          size="small"
          severity="danger"
          class="px-5 py-3 p-button-rounded font-bold w-auto white-space-nowrap"
          style="white-space: nowrap"
          [type]="'button'"
          label="{{ 'Delete selected' | translate }}"
          (click)="deleteRecycled()"></button>
        <!-- <button
          pButton
          size="small"
          severity="danger"
          class="px-5 py-3 p-button-rounded font-bold w-auto white-space-nowrap"
          style="white-space: nowrap"
          [type]="'button'"
          label="{{ 'Delete selected' | translate }}"
          (click)="deleteRecycled()"></button> -->
      }
    </div>
    @if (showUploader) {
      <p-fileUpload
        #uploader
        name="uploader[]"
        mode="advanced"
        [customUpload]="true"
        [multiple]="true"
        [accept]="accept"
        (uploadHandler)="onUpload($event)">
        <ng-template #content>
          <div class="py-5 my-3 bg-gray-500 rounded-xl text-center">
            {{ 'Press Choose or Drag and drop files to here to upload.' | translate }}
          </div>
        </ng-template>
      </p-fileUpload>
    }
    <div class="mr-1 p-1">
      <offconon-folder-breadcrumb
        [user_id]="user_id"
        [business_id]="business_id"
        [folderList]="folderDriveList()"
        [selected_folder_id]="folderId"
        [selectedChildrenParents]="selectedChildrenParents"
        [currentFolder]="currentFolder"
        (selectedIdChangeEmit)="selectedFolderId($event)" />
    </div>
    <div class="flex flex-wrap gap-4 t-5">
      <div class="flex-initial">
        <offconon-folder-list
          [user_id]="user_id"
          [business_id]="business_id"
          [folderList]="folderDriveList()"
          [selected_folder_id]="folderId"
          [selectedChildrenParents]="selectedChildrenParents"
          [currentFolder]="currentFolder"
          (selectedIdChangeEmit)="selectedFolderId($event)" />
      </div>

      @if (showImages && !loading) {
        <div class="flex-1">
          <offconon-media-folder-content
            [folderList]="folderDriveList()"
            [selectedChildren]="selectedChildren"
            [showType]="showType"
            [album_id]="folderId"
            [newFiles]="newFiles"
            [multiple]="multiple"
            [business_id]="business_id"
            [user_id]="user_id"
            (selectedFolderIdEmit)="selectedFolderId($event)"
            (EditImageEmit)="editImage($event)"
            (selectedItemEmit)="setSelectedItem($event)" />
        </div>
      }
      @if (folderId === 'Recycle') {
        <div class="ml-2 p-1">
          <offconon-media-recycle-content
            [language_id]="actual_language_id"
            [business_id]="business_id"
            [user_id]="user_id"
            (selectedItemEmit)="recycleSelected($event)" />
        </div>
      }
    </div>
  </div>
  <input #fileInput type="file" style="display: none" multiple (change)="onFileSelected($event)" />
}
@if (imageUrl && showImageCropper) {
  <div class="pt-2">
    <div class="flex justify-center flex-wrap pb-2 mb-2">
      <div class="flex items-center justify-center">
        <div class="p-2 text-center">
          <offconon-shared-ui-image-cropper
            #image
            [cropperOptions]="config"
            [imageUrl]="imageUrl"
            (export)="exportCroppedImage()" />
        </div>
      </div>
    </div>

    <div class="pb-2 mb-2 ml-2 mr-2 flex flex-wrap gap-3 justify-evenly">
      <button
        pButton
        icon="pi pi-check"
        iconPos="left"
        class="px-5 py-3 p-button-outlined p-button-success p-button-rounded font-bold w-auto white-space-nowrap"
        style="white-space: nowrap"
        label="{{ 'Save' | translate }}"
        (click)="cropImage()"></button>

      <button
        pButton
        icon="pi pi-caret-up"
        iconPos="left"
        class="px-5 py-3 p-button-outlined p-button-rounded font-bold w-auto white-space-nowrap"
        style="white-space: nowrap"
        label="{{ 'Move up' | translate }}"
        (click)="moveUpImage()"></button>

      <button
        pButton
        icon="pi pi-caret-down"
        iconPos="left"
        class="px-5 py-3 p-button-outlined p-button-rounded font-bold w-auto white-space-nowrap"
        style="white-space: nowrap"
        label="{{ 'Move down' | translate }}"
        (click)="moveDownImage()"></button>

      <button
        pButton
        icon="pi pi-caret-left"
        iconPos="left"
        class="px-5 py-3 p-button-outlined p-button-rounded font-bold w-auto white-space-nowrap"
        style="white-space: nowrap"
        label="{{ 'Move left' | translate }}"
        (click)="moveLeftImage()"></button>

      <button
        pButton
        icon="pi pi-caret-right"
        iconPos="left"
        class="px-5 py-3 p-button-outlined p-button-rounded font-bold w-auto white-space-nowrap"
        style="white-space: nowrap"
        label="{{ 'Move right' | translate }}"
        (click)="moveRightImage()"></button>

      <button
        pButton
        icon="pi pi-undo"
        iconPos="left"
        class="px-5 py-3 p-button-outlined p-button-rounded font-bold w-auto white-space-nowrap"
        style="white-space: nowrap"
        label="{{ 'Rotate left' | translate }}"
        (click)="rotateLeftImage()"></button>

      <button
        pButton
        icon="pi pi-refresh"
        iconPos="left"
        class="px-5 py-3 p-button-outlined p-button-rounded font-bold w-auto white-space-nowrap"
        style="white-space: nowrap"
        label="{{ 'Rotate right' | translate }}"
        (click)="rotateRightImage()"></button>

      <button
        pButton
        icon="pi pi-sync"
        iconPos="left"
        class="px-5 py-3 p-button-outlined p-button-warning p-button-rounded font-bold w-auto white-space-nowrap"
        style="white-space: nowrap"
        label="{{ 'Reset' | translate }}"
        (click)="resetCropper()"></button>

      <button
        pButton
        icon="pi pi-times"
        iconPos="left"
        class="px-5 py-3 p-button-outlined p-button-danger p-button-rounded font-bold w-auto white-space-nowrap"
        style="white-space: nowrap"
        label="{{ 'Cancel' | translate }}"
        (click)="reset()"></button>
    </div>
  </div>
}
<p-confirmDialog
  key="deleteMediaDialog"
  [breakpoints]="{ '960px': '75vw' }"
  [baseZIndex]="12"
  [appendTo]="'body'">
  <ng-template #headless let-message let-onAccept="onAccept" let-onReject="onReject">
    <div class="flex flex-col items-center p-8 bg-surface-0 dark:bg-surface-900 rounded">
      <span class="font-bold text-2xl block mb-2 mt-6">{{ message?.header }}</span>
      <p class="mb-0">{{ message?.message }}</p>
      <div class="flex items-center gap-2 mt-6">
        <p-button label="{{ 'Yes, I want to delete it.' | translate }}" (onClick)="onAccept()" />
        <p-button label="{{ 'Cancel' | translate }}" [outlined]="true" (onClick)="onReject()" />
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
<p-confirmDialog
  key="deleteRestoreDialog"
  [breakpoints]="{ '960px': '75vw' }"
  [baseZIndex]="12"
  [appendTo]="'body'">
  <ng-template #headless let-message let-onAccept="onAccept" let-onReject="onReject">
    <div class="flex flex-col items-center p-8 bg-surface-0 dark:bg-surface-900 rounded">
      <span class="font-bold text-2xl block mb-2 mt-6">{{ message?.header }}</span>
      <p class="mb-0">{{ message?.message }}</p>
      <div class="flex items-center gap-2 mt-6">
        <p-button label="{{ 'Yes, I want to delete it.' | translate }}" (onClick)="onAccept()" />
        <p-button label="{{ 'Cancel' | translate }}" [outlined]="true" (onClick)="onReject()" />
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
<p-dialog
  header="{{ 'Select a folder' | translate }}"
  [modal]="true"
  [closeOnEscape]="true"
  [resizable]="true"
  [closable]="true"
  [dismissableMask]="true"
  [maximizable]="true"
  [style]="{ width: '50rem' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [(visible)]="selectFolder"
  (onHide)="closeDialog()">
  <div class="p-2">
    <div class="pt-3">
      @if (selectFolder) {
        <offconon-folder-tree
          [folderList]="folderDriveList()"
          [selected_file_ids]="moveIds"
          [selected_file_album_id]="album_id"
          (MovedFilesEmit)="closeDialog()" />
      }
    </div>
  </div>
</p-dialog>
