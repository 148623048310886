@defer (when userBusinessEntity()) {
  <div
    class="relative flex items-center gap-2 rounded-full cursor-pointer header-profile"
    (click)="showInformation()">
    @if (userBusinessEntity()?.avatar_path) {
      <p-avatar
        shape="circle"
        [image]="userBusinessEntity()?.avatar_path"
        [size]="(themeService.mobileView | async) ? 'normal' : 'large'"
        [styleClass]="'border-2 border-gray-200/10  w-8 h-8 md:w-12 md:h-12 '" />
    } @else {
      @if (user?.full_name) {
        <offconon-monogram
          [avatarClass]="'w-10 h-10'"
          [options]="{ size: '2.5rem' }"
          [name]="user?.full_name || 'Guest'" />
      } @else {
        <div
          class="h-10 p-2 md:p-3 lg:px-3 lg:py-2 items-center hover:bg-blue-500 hover:text-white font-medium cursor-pointer transition-duration-150 transition-colors dark:text-white p-button-rounded"
          pTooltip="{{ 'Profile' | translate }}">
          <mat-icon>person</mat-icon>
        </div>
      }
    }

    @if (userBusinessEntity()) {
      @if (user) {
        <!--
        <div class="text-left hidden md:flex flex-col">
          <span class="text-sm font-medium">{{ userBusinessEntity()?.name }}</span>
            <span class="text-xs text-primary">{{
                userBusinessEntity()?.label || '' | translate
              }}</span>
        </div>
        -->
      }
    } @else {
      <div class="text-left hidden md:flex flex-col">
        <span class="text-sm font-medium dark:text-gray-300">{{ 'Guest' | translate }}</span>
      </div>
    }
    <!--
    <div class="hidden sm:flex">
      <i class="pi pi-angle-down text-gray"></i>
    </div>
    -->
  </div>
} @placeholder {
  <div class="relative flex items-center gap-2 md:pr-2 rounded-full cursor-pointer header-profile">
    <offconon-monogram
      [avatarClass]="'w-10 h-10'"
      [options]="{ size: '2.5rem' }"
      [name]="user?.full_name || 'Guest'" />
    <div class="text-left hidden md:flex flex-col">
      <span class="text-sm font-medium">{{ 'Guest' | translate }}</span>
    </div>
    <div class="hidden sm:block">
      <i class="pi pi-angle-down text-gray-400 ml-1"></i>
    </div>
  </div>
}

<p-drawer
  #infoSideBarRef
  position="right"
  [fullScreen]="false"
  [appendTo]="'body'"
  [modal]="true"
  [closeOnEscape]="true"
  [(visible)]="infoSideBar">
  <div class="flex items-center justify-between mb-3">
    <div class="text-center lg:text-left lg:pr-8">
      <a class="font-bold mb-1 text-xl cursor-pointer" [routerLink]="['/passport']">
        {{ 'OffConOn Passport' | translate }}
      </a>
    </div>
    <div class="mt-2 lg:mt-0 flex flex-wrap lg:flex-nowrap">
      <p-button icon="pi pi-times" [link]="true" (click)="showInformation()" />
    </div>
  </div>

  <offconon-start
    [isUserEntity]="isUserEntity"
    [user]="user"
    (openLanguageSelectorEvent)="openLang($event)"
    (hideMenuEvent)="showInformation()" />

  @for (mainItem of rightItems; track $index) {
    @if (mainItem?.visible !== false) {
      <div class="font-extrabold text-lg mb-1 mt-4">
        {{ mainItem.label | translate }}
      </div>
    }

    @for (item of mainItem.items; track $index) {
      @if (item?.visible !== false) {
        <a
          class="flex items-center py-1 cursor-pointer gap-2 left-menu-link"
          [routerLink]="item.link"
          (click)="item.commandname ? commandSelector(item.commandname) : openSubPage(item.link)">
          <span
            class="inline-flex items-center justify-center text-primary left-menu-icon"
            [pTooltip]="item.label | translate">
            <mat-icon fontIcon="{{ item.icon ? item.icon : 'add_circle' }}" />
          </span>
          <span class="inline-flex flex-col gap-2 opacity-60">
            <span class="text-sm">{{ item.label | translate }}</span>
          </span>
        </a>
      }
    }
  }
</p-drawer>
