@if (pipeline) {
  <div class="border-b border-gray pb-2">
    <div class="flex justify-between flex-wrap">
      <div class="flex items-center justify-center">
        {{ pipeline?.name }}
      </div>

      @if (parameters?.create_link) {
        <div class="flex items-center justify-center">
          <p-button
            icon="pi pi-plus"
            label="{{ 'Create' | translate }}"
            [text]="true"
            (click)="create()" />
        </div>
      }
    </div>
  </div>
  <offconon-steps-columns
    [createButton]="createButton"
    [pipeline]="pipeline"
    [cardTemplate]="cardTemplate">
    <ng-template #contentTemplate let-data>
      <ng-container *ngTemplateOutlet="contentTemplate; context: { $implicit: data }" />
    </ng-template>
  </offconon-steps-columns>
}
