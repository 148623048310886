import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Output,
  ViewChild,
  Input,
  OnInit,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { SelectModule } from 'primeng/select';

import { SharedService } from '@offconon/core-api';

import {
  addMediaToStorageSuccess,
  deleteSelectedMediaFromStorage,
  resetSelectedMediaIds,
} from '../../store/media-gallery.actions';
import { selectSelectedMediaItems } from '../../store/media-gallery.selectors';
import { MediaGalleryModule } from './media-gallery.module';
import { MediaGalleryContentComponent } from './media-gallery-content/media-gallery-content.component';

@Component({
  selector: 'offconon-media-gallery',
  imports: [
    CommonModule,
    ButtonModule,
    MediaGalleryContentComponent,
    TranslateModule,
    MediaGalleryModule,
    SelectModule,
    FormsModule,
  ],
  templateUrl: './media-gallery.component.html',
  styles: ``,
})
export class MediaGalleryComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  @Input() multiple = false;
  @Input() accept = 'image/*';
  @Output() mediaGalleryOpened = new EventEmitter<boolean>();
  @Output() selectedMediaEmit = new EventEmitter<any>();
  private store = inject(Store);
  selectedMedia = toSignal(this.store.select(selectSelectedMediaItems));
  private sharedService = inject(SharedService);

  public acceptedTypes: any[] = [];
  public showType: 'image' | 'video' | 'audio' | 'document' | 'zip' | 'other' = 'image';
  public options: {
    name: string;
    code: 'image' | 'video' | 'audio' | 'document' | 'zip' | 'other';
  }[] = [{ name: 'Image', code: 'image' }];
  public optionTypes: string[];

  ngOnInit(): void {
    this.acceptedTypes = this.accept.split(',').map((type) => type.split('*')[0]);
    this.optionTypes = this.accept.split(',').map((type) => type.split('/')[0]);
    this.options = this.optionTypes
      .map((element) => {
        return {
          code: element.toLowerCase() as 'image' | 'video' | 'audio' | 'document' | 'zip' | 'other',
          name: element.charAt(0).toUpperCase() + element.slice(1),
        };
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
  }

  openFileInput() {
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: any) {
    const fileList: File[] = event.target.files;

    if (fileList && fileList.length > 0) {
      const files: File[] = Array.from(fileList);

      //todo: find out why the effect is not working
      this.sharedService.sharedMediaStorageCreate(files).subscribe((res: any) => {
        this.store.dispatch(addMediaToStorageSuccess({ media: res }));
      });
    }
  }
  closeMediaGallery() {
    this.mediaGalleryOpened.emit(false);
  }
  addToEditor() {
    this.selectedMediaEmit.emit(this.selectedMedia());
    this.store.dispatch(resetSelectedMediaIds());

    this.closeMediaGallery();
  }

  deleteMedia() {
    this.store.dispatch(deleteSelectedMediaFromStorage());
    this.store.dispatch(resetSelectedMediaIds());
  }
}
