import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import { Pipeline } from '@offconon/core-api';

import * as PipelineActions from './pipeline.actions';
import { PipelineEntity } from './pipeline.models';

export const PIPELINE_FEATURE_KEY = 'pipeline';

export interface PipelineState extends EntityState<PipelineEntity> {
  error?: string | null; // last known error (if any)
  pipeline?: Pipeline;
  stepsLoaded: boolean;
}

export interface PipelinePartialState {
  readonly [PIPELINE_FEATURE_KEY]: PipelineState;
}

export const pipelineAdapter: EntityAdapter<PipelineEntity> = createEntityAdapter<PipelineEntity>();

export const initialPipelineState: PipelineState = pipelineAdapter.getInitialState({
  // set initial required properties
  stepsLoaded: false,
});

const reducer = createReducer(
  initialPipelineState,
  on(PipelineActions.initPipeline, (state) => ({ ...state, loaded: false, error: null })),
  on(PipelineActions.loadPipelineSuccess, (state, { pipeline }) =>
    pipelineAdapter.setAll(pipeline, { ...state, loaded: true }),
  ),
  on(PipelineActions.fetchPipeline, (state) => ({ ...state, stepsLoaded: false })),

  on(PipelineActions.loadPipelineFailure, (state, { error }) => ({ ...state, error })),
  on(PipelineActions.fetchPipelineSuccess, (state, { pipeline: paginatedPipelineList }) => ({
    ...state,
    pipeline: paginatedPipelineList,
  })),
  on(PipelineActions.deleteStepSuccess, (state, { id }) => {
    return {
      ...state,
      pipeline: {
        ...state.pipeline,
        steps: state.pipeline?.steps?.filter((step) => step.id !== id),
      },
    };
  }),
  on(PipelineActions.addStepSuccess, (state, { pipelineStep }) => {
    return {
      ...state,
      pipeline: {
        ...state.pipeline,
        steps: state.pipeline?.steps
          ? [
              ...state.pipeline.steps.slice(0, pipelineStep.order_id),
              pipelineStep,
              ...state.pipeline.steps.slice(pipelineStep.order_id),
            ]
          : [pipelineStep],
      },
    };
  }),
  on(PipelineActions.editStepSuccess, (state, { pipelineStep }) => {
    const filteredSteps = state.pipeline?.steps?.filter((step) => step.id !== pipelineStep.id);

    const steps = [
      ...(filteredSteps?.slice(0, pipelineStep.order_id) ?? []),
      pipelineStep,
      ...(filteredSteps?.slice(pipelineStep.order_id) ?? []),
    ];

    return {
      ...state,
      pipeline: {
        ...state.pipeline,
        steps,
      },
    };
  }),
);

export function pipelineReducer(state: PipelineState | undefined, action: Action) {
  return reducer(state, action);
}
