import { CommonModule } from '@angular/common';
import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
  FormControl,
} from '@angular/forms';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { SelectModule } from 'primeng/select';
import { Textarea } from 'primeng/textarea';

import { Album } from '@offconon/core-api';
import { UniversalLanguage } from '@offconon/core-api';
import { UploadService } from '@offconon/core-api';
import { LangaugeDropdownVirtualScrollCommonComponent } from '@offconon/shared/ui/langauge-dropdown-virtual-scroll-common';
import { SiteLanguageService } from '@offconon/shared/utils/services/site-language';
import { UniversalLanguageTranslationSaveEditService } from '@offconon/shared/utils/services/universal-language-translation-save-edit';

@Component({
  selector: 'offconon-media-album-form-common',
  templateUrl: './media-album-form-common.component.html',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    Textarea,
    SelectModule,
    ButtonModule,
    TranslateModule,
    LangaugeDropdownVirtualScrollCommonComponent,
    FloatLabelModule,
    CommonModule,
  ],
})
export class MediaAlbumFormCommonComponent implements OnInit {
  private fb = inject(FormBuilder);
  private translateService = inject(TranslateService);
  private messageService = inject(MessageService);
  private uploadService = inject(UploadService);
  private siteLanguageService = inject(SiteLanguageService);
  private universalLanguageTranslationSaveEditService = inject(
    UniversalLanguageTranslationSaveEditService,
  );

  @Input() user_id: any;
  @Input() business_id: any;
  @Input() album_id: any;
  @Output() formSavedEvent = new EventEmitter<any>();

  private destroyRef = inject(DestroyRef);

  loading = false;
  form: FormGroup;
  media_share_option: any;
  actual_language_id = 71;
  language_parameters: any = {};

  ngOnInit() {
    this.language_parameters = {
      translation_supported: true,
    };

    this.media_share_option = [
      {
        name: this.translateService.instant('Private'),
        code: Album.MediaShareEnum.Nobody,
      },
      {
        name: this.translateService.instant('Public'),
        code: Album.MediaShareEnum.Anybody,
      },
      {
        name: this.translateService.instant('Those who follow me'),
        code: Album.MediaShareEnum.ThoseFollowMe,
      },
      {
        name: this.translateService.instant('Who I follow'),
        code: Album.MediaShareEnum.WhoIFollow,
      },
      {
        name:
          this.translateService.instant('Those who follow me') +
          ' | ' +
          this.translateService.instant('Who I follow'),
        code: Album.MediaShareEnum.Both,
      },
    ];

    this.actual_language_id = this.siteLanguageService.actualSiteLanguageId();

    this.form = this.fb.group({
      media_share: [Album.MediaShareEnum.Anybody, [Validators.required]],
      business_id: [this.business_id],
      title: ['', [Validators.maxLength(25), Validators.minLength(2), Validators.required]],
      description: ['', [Validators.maxLength(85), Validators.minLength(2)]],
      language_id: [this.actual_language_id],
    });
  }

  get languageFormControl() {
    return this.form.get('language_id') as FormControl;
  }

  submit() {
    this.loading = true;
    let payload: any = {};
    payload = this.form.value;
    if (this.album_id) {
      //payload.album_id = this.album_id;
      payload.parent_id = this.album_id;
    }
    if (payload.description === '') {
      payload.description = null;
    }

    let universal_translation_payload: any = {};

    this.uploadService
      .uploadAlbumCreate(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result: Album | any) => {
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('Successful'),
            detail: this.translateService.instant('Your data has been successfully saved.'),
          });

          if (result) {
            universal_translation_payload = {
              language_id: payload.language_id,
              name: payload.title,
              description: payload.description,
              service_id: result.id,
              service_type: 'album_detail',
            };

            this.universalLanguageTranslationSaveEditService
              .postUniversalLanguageTranslation(universal_translation_payload)
              .subscribe({
                next: (data: UniversalLanguage) => {
                  result.album_detail = [data];

                  this.formSavedEvent.emit(result);
                },
              });
          }
        },

        complete: () => {
          this.loading = false;
        },
      });
  }
}
